import React, { useState } from 'react';
import './sidebar.css';
import { FaBars } from 'react-icons/fa';
import newicon from "../images/newicon.svg";
import Homeicon from "../images/Homeicon.svg";
import Chat from "../images/chat.svg";
import Bell from "../images/bell.svg";
import Live from "../images/wifi.svg";
import Profile from "../images/profile.svg";
import Topics from "../images/slash.svg";
import Settings from "../images/settings.svg";
import SubscriptionIcon from "../images/subscription.png"; // Add subscription icon
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    Cookies.remove("token");
    navigate("/login");
  };

  return (
    <>
      {/* Burger button for mobile */}
      <button className="burger-btn" onClick={toggleSidebar}>
        <FaBars />
      </button>

      {/* Sidebar */}
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className='sidebar-logo-container'>
          <div className='img-sidebar'>
            <img src={newicon} alt="logo" className="logo" />
          </div>
          <div className='sidebar-heading'>
            <p className='header1'>Put me</p>
            <p className='header2'>in touch</p>
          </div>
        </div>

        <ul className="menu">
  <li>
    <Link to="/home"><img src={Homeicon} alt="Home" className="menu-icon" /> Home</Link>
  </li>
  <li>
    <Link to="/discuss"><img src={Chat} alt="Discuss" className="menu-icon" /> Discuss</Link>
  </li>
  <li>
    <Link to="/notifications"><img src={Bell} alt="Notifications" className="menu-icon" /> Notifications</Link>
  </li>
  <li >
            <Link to="/busmedia">
              <img src={Live} alt="Media" className="menu-icon" /> Media
            </Link>
          </li>
  <li>
    <Link to="/live"><img src={Live} alt="Live" className="menu-icon" /> Live</Link>
  </li>
  <li>
    <Link to="/mainProfile"><img src={Profile} alt="Profile" className="menu-icon" /> Profile</Link>
  </li>
  <li>
    <Link to="/subscription"><img src={SubscriptionIcon} alt="Subscription" className="menu-icon" /> Subscription</Link>
  </li>
  {/* <li>
    <img src={Topics} alt="Topics" className="menu-icon" /> Topics
  </li> */}
 <li>
      <Link to="/busisettings" style={{ textDecoration: 'none', color: 'inherit' }}>
        <img src={Settings} alt="Settings" className="menu-icon" /> Settings
      </Link>
    </li>
</ul>


        {/* Sidebar Buttons - Hide these in desktop view */}
        <div className="sidebar-consumer-butons">
          <button className="sidebar-back-buttons"><Link to="/user">Back</Link></button>
          <button className="sidebar-logout-buttons" onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Tooltip,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ConfidentialInformationForm = ({ registeredId }) => {
  const navigate = useNavigate();  // Initialize the navigate hook

  console.log("hii");
  console.log(registeredId);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    username: '',
    companyName: '',
    email: '',
    mobileNumber: '',
    address: '',
    gstNumber: '',
  });

  const [loading, setLoading] = useState(false);

  // Fetch user data
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://api.pmitnetwork.com/api/getUser/${registeredId}`);
        console.log("confidentialdata",response.data);
        setFormData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
    fetchData();
  }, [registeredId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await axios.put('https://api.pmitnetwork.com/api/updateUser', { id: registeredId, ...formData });
      alert('Data updated successfully!');
    } catch (error) {
      console.error('Error updating user data:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleContinue = () => {
    navigate(`/profile?section=Plans&registeredId=${registeredId}`); // Navigate to the "Proofs" tab
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        padding: 4,
        margin: '0 auto',
        fontFamily: 'Manrope, sans-serif',
        color: '#4B4C4C',
        backgroundColor: '#ffffff',
        borderRadius: 2,
        boxShadow: 2,
      }}
    >
    
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
        Confidential Information
      </Typography>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
             
                <TextField
                label="Your Name"
                name="username"
                variant="outlined"
                size="medium"
                fullWidth
                required
                value={formData.username}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                name="lastName"
                variant="outlined"
                size="medium"
                fullWidth
                required
                value={formData.lastName}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  labelId="gender-label"
                  name="gender"
                  variant="outlined"
                  size="medium"
                  value={formData.gender}
                  onChange={handleChange}
                  label="Gender"
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {/* <TextField
                label="Your Name"
                name="username"
                variant="outlined"
                size="medium"
                fullWidth
                required
                value={formData.username}
                onChange={handleChange}
              /> */}
               <TextField
                label="Username"
                name="firstName"
                variant="outlined"
                size="medium"
                fullWidth
                required
                value={`${formData.username} .${formData.lastName}`}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Tooltip title="This helps us verify your business identity.">
                <TextField
                  label="Company Name"
                  name="companyName"
                  variant="outlined"
                  size="medium"
                  fullWidth
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Tooltip title="Used to send business notifications and updates.">
                <TextField
                  label="Email"
                  name="email"
                  variant="outlined"
                  size="medium"
                  fullWidth
                  required
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Tooltip title="Your mobile number is used to verify your account.">
                <TextField
                  label="Mobile Number"
                  name="mobileNumber"
                  variant="outlined"
                  size="medium"
                  fullWidth
                  required
                  value={formData.mobileNumber}
                  onChange={handleChange}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Address"
                name="address"
                variant="outlined"
                size="medium"
                fullWidth
                required
                value={formData.address}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="GST Number"
                name="gstNumber"
                variant="outlined"
                size="medium"
                fullWidth
                required
                value={formData.gstNumber}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                fullWidth
                onClick={handleSave}
                sx={{
                  fontWeight: 'bold',
                  padding: '10px 16px',
                  marginTop: 2,
                }}
              >
                Save
              </Button>
              {/* <Button
                variant="contained"
                color="secondary"
                size="medium"
                fullWidth
                onClick={handleContinue}  // Navigate on click
                sx={{
                  fontWeight: 'bold',
                  padding: '10px 16px',
                  marginTop: 2,
                }}
              >
                Continue
              </Button> */}
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ConfidentialInformationForm;
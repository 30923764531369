import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate,useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
   Toolbar,
   Grid,
} from "@mui/material";
import {
  LocationOn as LocationIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Badge as BadgeIcon,
  Work as WorkIcon,
  Business as BusinessIcon,
  Person as PersonIcon,
  CameraAltOutlined as CameraIcon,
} from "@mui/icons-material";
import { MdDateRange } from "react-icons/md";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import Confidential from "./Confidential";
import Business from "./Business";
import Proofs from "./Proofs";
import Plans from "./Plans";

const HRProfile = () => {
  const [currentSection, setCurrentSection] = useState("INFO");
  const [image, setImage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Get location object to read query params


  const [userDetails, setUserDetails] = useState({});
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "user") {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "user") {
        navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `https://api.pmitnetwork.com/api/getuserdetails/afterlogin/${username}`
        );
        setUserDetails(response.data[0]);
        setImage(response.data[0]?.profileimage);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    if (username) {
      fetchUserDetails();
    }
  }, [username]);

  useEffect(() => {
    const section = new URLSearchParams(location.search).get("section");
    console.log("sec",section)
    if (section && ["INFO", "Proofs", "Business", "Plans"].includes(section)) {
      setCurrentSection(section); // Set the section based on the query parameter
    }
  }, [location])

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);
    formData.append("registeredId", userDetails.registeredId);

    try {
      const response = await axios.post(
        "https://api.pmitnetwork.com/api/uploadImage",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setImage(response.data.image); 
      setAnchorEl(null);
      document.getElementById("image-upload-input").value = "";

    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleDeleteImage = async () => {
    try {
      await axios.delete(
        `https://api.pmitnetwork.com/api/deleteImage/${userDetails.registeredId}`
      );
      setImage(null); 
      setDeleteConfirmationOpen(false);
      setAnchorEl(null); 
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleMenuClose = () => setAnchorEl(null);

  const userInfo = [
    { icon: <PersonIcon />, text: userDetails.username || "" },
    { icon: <PhoneIcon />, text: userDetails.mobileNumber || "" },
    { icon: <EmailIcon />, text: userDetails.email || "" },
    { icon: <WorkIcon />, text: userDetails.Job_Position || "business" },
    { icon: <BusinessIcon />, text: userDetails.Department || "Businesstype" },
    { icon: <LocationIcon />, text: userDetails.address || "" },
    {
      icon: <MdDateRange style={{ fontWeight: "bold", fontSize: "22px" }} />,
      text: `Joined: ${userDetails.DateofRegistered}` || "",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100vh",
        overflow: "hidden",
        bgcolor: "background.default",
      }}
    >
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ p: { xs: 1, sm: 2 } }}
      >
        <Grid item xs={12} sm={4} md={3}>
          <Button
            color="inherit"
            onClick={() => navigate("/mainProfile")}
            sx={{
              bgcolor: "primary.main",
              color: "primary.contrastText",
              mt: "2",
              fontSize: { xs: "0.8rem", sm: "1rem" },
            }}
          >
            Go Back
          </Button>

          <Box
            component="label"
            htmlFor="image-upload-input"
            sx={{
              width: 150,
              height: 150,
              borderRadius: "50%",
              overflow: "hidden",
              position: "relative",
              cursor: "pointer",
              backgroundColor: "#f0f0f0",
              mx: "auto",
            }}
          >
            {image ? (
              <img
                src={`https://api.pmitnetwork.com/uploads/${image}`}
                alt="Profile"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <PersonIcon sx={{ width: "70%", height: "70%", color: "#666" }} />
            )}
            <input
              id="image-upload-input"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
            <IconButton
              sx={{
                position: "absolute",
                bottom: 8,
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                borderRadius: "50%",
              }}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <CameraIcon />
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() =>
                document.getElementById("image-upload-input").click()
              }
            >
              Change Picture
            </MenuItem>
            {image && (
              <MenuItem onClick={() => setDeleteConfirmationOpen(true)}>
                Delete Picture
              </MenuItem>
            )}
          </Menu>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <Typography variant="h5" align="center">
            {userDetails.username || "Santhosh Majji"}
          </Typography>
          <Grid container spacing={2} mt={2}>
            {userInfo.map((info, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box display="flex" alignItems="center">
                  {info.icon}
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {info.text}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <AppBar
        position="static"
        sx={{ mt: 2, bgcolor: "primary.main", color: "primary.contrastText" }}
      >
        <Toolbar sx={{ justifyContent: "space-around" }}>
          {["INFO"].map((section) => (
            <Button
              key={section}
              color="inherit"
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem" },
                borderBottom:
                  currentSection === section ? "2px solid white" : "none",
                fontWeight: currentSection === section ? "bold" : "normal",
              }}
              onClick={() => setCurrentSection(section)}
            >
              {section}
            </Button>
          ))}
        </Toolbar>
      </AppBar>

      <Box sx={{ mt: 2, p: { xs: 2, sm: 3 }, width: "100%", flexGrow: 1 }}>
        {currentSection === "INFO" && <Confidential registeredId={userDetails.registeredId}  />}
        {/* {currentSection === "Proofs" && <Proofs registereddId={userDetails.registeredId}/>} */}
        {/* {currentSection === "Business" && <Business registereddId={userDetails.registeredId}/>} */}
        {/* {currentSection === "Plans" && <Plans  registeredId={userDetails.registeredId}/>} */}
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Delete Profile Picture</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your profile picture?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteImage} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HRProfile;


import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import "../components/Navbarpage.css";
import { TiMessages } from "react-icons/ti";
import  logo from '../images/Logo.svg'
import { useNavigate } from 'react-router-dom';


function ExploreNavbar({type}) {
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate('/log', { state: { type } }); // Pass type in the state
    };
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-light-subtle custom-navbar">
      <Container>
        <Navbar.Brand>
        <div className='ps-logo-container'>
        <div className='img-cont'>

        <img src={logo} alt="logo" className='img-lo-di'/>

        {/* <TiMessages className='logo-dimensions-ps'/> */}

        </div>
        <div className='text-cont'>
            <p className='head-ps-1'>Put me</p>
            <p className='head-ps-2'>in touch</p>
        </div>
        </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          <Nav>
            <Nav.Link  onClick={handleLoginClick}>
                <button className="signup-part1-button">Login</button>
            </Nav.Link>
            {/* <Nav.Link eventKey={2} href="#memes">
              signup
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default ExploreNavbar;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate,Link } from 'react-router-dom'; 
import './Registerfile.css';
import spimage from "../images/collabrate.png";
import Navbarpage from '../components/Navbar';
import lin from "../images/linkdin.svg"; // Import LinkedIn image
import go from "../images/google.svg"; 
import RegisterNavabar from '../components/RegisterNavabar';

import { FaRegArrowAltCircleLeft } from "react-icons/fa";

const Login = () => {
  const [name, setName] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
 const [otp, setOtp] = useState('');
  const [role, setRole] = useState(''); 
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);  // For showing the success popup
  const navigate = useNavigate();

  const sendOtp = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.pmitnetwork.com/send-otp', { email });
      if (response.data.success) {
        setIsOtpSent(true);
        console.log('OTP sent to email');
      } else {
        setErrorMessage(response.data.message);  // Set the error message from the backend
        setIsErrorModalVisible(true);  // Show the error modal
        console.log('Failed to send OTP:', response.data.message);
      }
    } catch (error) {
      if (error.response) {
        // If there is a response from the server, get the message from it
        setErrorMessage(error.response.data.message || 'An error occurred');  // Get the specific error message
      } else {
        setErrorMessage('An error occurred while sending OTP');  // Set a generic error message
      }
      setIsErrorModalVisible(true);  // Show the error modal
      console.log('An error occurred:', error);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.pmitnetwork.com/verify-otp', { email, otp });
      if (response.data.success) {
        setIsOtpVerified(true);
        console.log('OTP verified');
      } else {
        console.log('OTP verification failed:', response.data.message);
      }
    } catch (error) {
      console.log('An error occurred:', error);
    }
  };

  // Automatically sign up the user if OTP is verified
  useEffect(() => {
    if (isOtpVerified) {
      handleSignup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOtpVerified]);


  const handlePasswordChange = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);
   
    // Regular expression to validate password
    const passwordValidationPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
 
    // Validate password against the pattern
    if (!passwordValidationPattern.test(inputValue)) {
      setPasswordError('Password must be at least 8 characters long, and include at least one uppercase letter, one lowercase letter, one digit, and one special character.');
    } else {
      setPasswordError('');
    }
  };

  const handleSignup = async () => {
    try {
      const response = await axios.post('https://api.pmitnetwork.com/login', { name, email, password,role:"user"});
      if (response.data.success) {
        console.log('User registered successfully');
        setIsSuccessModalVisible(true);  // Show the success popup
        // Automatically close the modal after 3 seconds
        // setTimeout(() => setIsSuccessModalVisible(false), 3000);
      } else {
        console.log('Registration failed:', response.data.message);
      }
    } catch (error) {
      console.log('An error occurred during registration:', error);
    }
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalVisible(false);
  };

  const handleCloseModal = () => {
    setIsSuccessModalVisible(false);
    navigate('/login'); // Redirect to login page
  };

  return (
    <div className='spmain-container bg-light-subtle'> 
      {/* <Navbarpage />  */}
      <RegisterNavabar/>
    <div className="container  d-flex pt-3 align-items-center justify-content-center text-align-center  p-3 sp-main  ">
     
     
      <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
      <div>
        <h1 className="sp-heading">Connect, Collaborate. <br/>Grow Together!</h1>
       
        <p className="subtext">
        <span className="spline-1">Effortlessly manage your schedule with our</span>
      <span className="spline-2">appointment software your calendar is always</span>
      <span className='spline-3'> ready to book!</span>
      </p>
        <div className="spimage-container">
          <img
            src={spimage}
            alt="Register Illustration"
            className="spimage w-100"
            data-aos="fade-in"
    data-aos-duration="1000"
          />
          <div className="spimage-text">#Colloborate virtually anywhere!</div>
        </div>
        </div>
      </div>
    <div className="d-flex justify-content-center col-lg-6 col-md-12 col-s-12 mt-3">
    <div className='sp-account-container pt-2'>
      <h2 className='spaccount-heading'>Create your account</h2>
      <div className="splogin-option mt-4">
       
        <img src={go} alt="google" className="spicong" />
        <span className="splogin-text">Login with Google</span>
      </div>
      <div className="splogin-option mt-4">
      <img src={lin} alt="LinkedIn" className="spiconl" />
        <span className="splogin1-text">Login with LinkedIn</span>
      </div>
      <div className="spor-container mt-3">
        <hr className="spor-line" />
        <span className="spor-text">or</span>
        <hr className="spor-line1" />
      </div>
      {!isOtpSent && (
        <form onSubmit={sendOtp}>
          <div className="form-group col-lg-11 mt-3">
           
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder='Name'
              className='form-control border-0 border-bottom border-dark sp-name'
              style={{
                outline:'0'
               
              }}
            />
          </div>
          <div className="form-group col-lg-11 mt-3">
         
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder='Email'
              className='form-control border-0 border-bottom border-dark sp-email'
              style={{
                outline:'0'
               
              }}
            />
          </div>
          <div className="form-group col-lg-11 mt-3">
         
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              required
              placeholder='Password'
              className='form-control border-0 border-bottom border-dark sp-pass'
              style={{
                outline:'0'
               
              }}
            />
            {passwordError && <p className="error-message">{passwordError}</p>}
 
          </div>
          <button className="sp-button mt-3" type="submit">Signup</button>
          <div>
          <Link to="/"  className="homepage-link"><FaRegArrowAltCircleLeft  className="homepage-icon" />Back</Link>
          </div>
        </form>
      )}
 
      {isOtpSent && !isOtpVerified && (
        <form onSubmit={verifyOtp}>
          <div className="form-group">
           
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              placeholder='Enter OTP'
              className='form-control border-0 border-bottom border-dark sp-otp mt-3'
              style={{
                outline:'0'
               
              }}
            />
          </div>
          <button className="sp1-button mt-3" type="submit">Verify OTP</button>
        </form>
      )}
 
      {/* Success Modal */}
      {/* Success Modal */}
      {isSuccessModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={handleCloseModal}>×</span>
            <h2>Success!</h2>
            <p>User successfully registered</p>
            <div className="checkmark">&#10004;</div>
          </div>
        </div>
      )}
      {isErrorModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={handleCloseErrorModal}>×</span>
            <h2>Error!</h2>
            <p>{errorMessage}</p>
          </div>
        </div>
      )}
      </div>
    </div>
   </div>
    </div>
  );
};
 
export default Login;
 

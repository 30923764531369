// import React from 'react';
// import './Secondpartmain.css';
// import img1 from "../images/Antiqueshop.jpg";
// import img2 from "../images/user1.jpg";

// const cardData = [
//   {
//     image: img1,
//     title: 'Card Title 1',
//     description: 'This is the description for card 1.',
//   },
//   {
//     image: img2,
//     title: 'Card Title 2',
//     description: 'This is the description for card 2 frhyuyjh jhourh hjiyuehe gyigngeuhb.',
//   },
// ];

// const Secondpartmain = () => {
//   return (
//     <div className="ps-card-container">
//       {cardData.map((card, index) => (
//         <div className="ps-card" key={index}>
//           <div className="ps-image-container">
//             <img src={card.image} alt={card.title}  className='ps-img-dimen'/>
//           </div>
//           <div className="ps-content">
//             <h3 className="ps-title">{card.title}</h3>
//             <p className="ps-description">{card.description}</p>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Secondpartmain;





import React from 'react';
import './Secondpartmain.css';
import img1 from "../images/plant.jpeg";
import img2 from "../images/shakehand.png";
import img3 from "../images/interaction.jpg"
import { IoIosArrowDown } from "react-icons/io";




const Secondpartmain = () => {
  return (
    <div className="ps-card-container">
        <div className="ps-card">
          <div className="ps-image-container">
            <img src={img2} className='ps-img-dimen' />
            <IoIosArrowDown className="ps-icon" />

          </div>
          <div className="ps-content">
            <h3 className="ps-title">Targeted connections</h3>
            <p className="ps-description">
             No more wasted time. Our Advanced algorithms match you with bussiness, partners and professionals that perfectly align with your needs.       
            </p>
          </div>
        </div>
        <div className="ps-card">
          <div className="ps-image-container">
            <img src={img1} className='ps-img-dimen' />
            <IoIosArrowDown className="ps-icon" />

          </div>
          <div className="ps-content">
            <h3 className="ps-title">Grow with Confidence</h3>
            <p className="ps-description">Discover potential clients, suppliers,or patners, and foster long term relationships in a trusted environment. </p>
          </div>
        </div>
        <div className="ps-card">
          <div className="ps-image-container">
            <img src={img3} className='ps-img-dimen' />
          
            <IoIosArrowDown className="ps-icon" />
          

          </div>
          <div className="ps-content">
            <h3 className="ps-title">Collaborate efficiency</h3>
            <p className="ps-description">Communication, collaborate  and close deals faster with our intuitive tools and realtime messaging features.</p>
          </div>
        </div>
    </div>
  );
};

export default Secondpartmain;

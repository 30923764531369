import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Button,
  Paper,
  Tabs,
  Tab,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const notifications = [
  {
    id: 1,
    category: 'B2B',
    title: 'Partnership Opportunity with XYZ Corp',
    message: 'We are looking to partner with your company for a long-term business venture.',
    time: new Date(new Date().setDate(new Date().getDate() - 5)),
    avatar: 'https://via.placeholder.com/50',
  },
  {
    id: 2,
    category: 'B2C',
    title: 'Discount Alert: 50% off on all services',
    message: 'Exclusive offer for our premium customers. Avail it now!',
    time: new Date(new Date().setDate(new Date().getDate() - 15)),
    avatar: 'https://via.placeholder.com/50',
  },
  {
    id: 3,
    category: 'B2B',
    title: 'Client Meeting Scheduled',
    message: 'A meeting with ABC Pvt Ltd has been scheduled for next Monday.',
    time: new Date(new Date().setDate(new Date().getDate() - 31)),
    avatar: 'https://via.placeholder.com/50',
  },
];

const approvedPosts = [
  { id: 1, title: 'Approved Partnership with XYZ Corp', details: 'Details about the partnership approval.' },
  { id: 2, title: 'Approved Post Title 2', details: 'Details about another approved post.' },
];

const rejectedPosts = [
  { id: 1, title: 'Rejected Post Title 1', details: 'Details about the rejection.' },
  { id: 2, title: 'Rejected Proposal', details: 'Details about the rejected proposal.' },
];

const ProfileSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  height: 'fit-content',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    width: '25%',
  },
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
}));

const NotificationsSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  height: 'auto',
  maxHeight: '70vh',
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
}));

const FixedSubscriptionSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  position: 'sticky',
  top: theme.spacing(4),
  textAlign: 'center',
  height: 'fit-content',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: '25%',
  },
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
}));

const UpgradeButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  color: '#fff',
  padding: theme.spacing(1, 3),
  marginTop: theme.spacing(2),
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
    boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.15)',
  },
}));

const NotificationItem = ({ category, title, message, time, avatar }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <ListItem alignItems="flex-start" sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box display="flex">
        <ListItemAvatar>
          <Avatar src={avatar} alt="avatar" />
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <Typography variant="subtitle2" fontWeight="bold">
                {title} ({category})
              </Typography>
            </>
          }
          secondary={
            <>
              <Typography variant="body2" color="textSecondary">
                {message}
              </Typography>
              <Typography variant="caption" color="textSecondary" display="block">
                {time.toLocaleString()}
              </Typography>
            </>
          }
        />
      </Box>
      <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleMenuClose}>Mark as Read</MenuItem>
        <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
        <MenuItem onClick={handleMenuClose}>Report</MenuItem>
      </Menu>
    </ListItem>
  );
};

const TabContent = ({ content }) => (
  <List>
    {content.map((item) => (
      <React.Fragment key={item.id}>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<Typography variant="subtitle2" fontWeight="bold">{item.title}</Typography>}
            secondary={<Typography variant="body2" color="textSecondary">{item.details}</Typography>}
          />
        </ListItem>
        <Divider />
      </React.Fragment>
    ))}
  </List>
);

const NotificationsPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const today = new Date();
    const recentNotifications = notifications.filter(
      (notification) => (today - notification.time) / (1000 * 60 * 60 * 24) <= 30
    );
    setFilteredNotifications(recentNotifications);
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      {/* Go Back Button */}
      <IconButton sx={{ mb: 2 }} onClick={() => navigate('/home')}>
        <ArrowBackIcon />
      </IconButton>

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', gap: 2 }}>
        {/* Left Profile Section */}
        <ProfileSection>
          <Avatar src="https://via.placeholder.com/100" alt="profile-avatar" sx={{ width: 80, height: 80, mx: 'auto' }} />
          <Typography variant="h6" sx={{ mt: 1 }}>Santhoshkumar Majji</Typography>
          <Typography variant="body2" color="textSecondary">
            B2b and B2c
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
Put me In Touch          </Typography>
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
            Manage Your Notifications
          </Button>
        </ProfileSection>

        {/* Center Notifications Section */}
        <NotificationsSection>
          <Box display="flex" alignItems="center" mb={2}>
            <NotificationsIcon fontSize="large" color="primary" />
            <Typography variant="h6" ml={1}>Notifications</Typography>
          </Box>
          <Tabs
            value={tabValue}
            onChange={(e, value) => setTabValue(value)}
            centered
            sx={{
              '& .MuiTab-root': {
                textTransform: 'none',
                fontWeight: 'bold',
                transition: 'all 0.3s',
                '&:hover': {
                  color: 'primary.main',
                },
              },
              '& .Mui-selected': {
                color: 'primary.main',
              },
            }}
          >
            <Tab label="All" />
            <Tab label="Approved Posts" />
            <Tab label="Rejected Posts" />
          </Tabs>

          {tabValue === 0 && (
            <List>
              {filteredNotifications.map((notification) => (
                <React.Fragment key={notification.id}>
                  <NotificationItem {...notification} />
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )}
          {tabValue === 1 && <TabContent content={approvedPosts} />}
          {tabValue === 2 && <TabContent content={rejectedPosts} />}
        </NotificationsSection>

        {/* Right Subscription Section */}
        <FixedSubscriptionSection>
          <Typography variant="h6" fontWeight="bold">
            Premium Plan
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Your current plan includes advanced features and analytics.
          </Typography>
          <UpgradeButton>Upgrade to Premium</UpgradeButton>
        </FixedSubscriptionSection>
      </Box>
    </Box>
  );
};

export default NotificationsPage;

import React, { useState, useEffect } from "react";
import MessageInput from "./MessageInput";
import "./ChatWindow.css";
import monkey from "../images/monkey.png";

// Utility function to format date and time
const formatDate = (timestamp) => {
  const messageDate = new Date(timestamp);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const isToday =
    messageDate.getDate() === today.getDate() &&
    messageDate.getMonth() === today.getMonth() &&
    messageDate.getFullYear() === today.getFullYear();

  const isYesterday =
    messageDate.getDate() === yesterday.getDate() &&
    messageDate.getMonth() === yesterday.getMonth() &&
    messageDate.getFullYear() === yesterday.getFullYear();

  const timeString = messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  if (isToday) {
    return `Today, ${timeString}`;
  } else if (isYesterday) {
    return `Yesterday, ${timeString}`;
  } else {
    return messageDate.toLocaleDateString() + `, ${timeString}`;
  }
};

const ChatWindow = ({ selectedChat }) => {
  const [chatMessages, setChatMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch(`https://api.pmitnetwork.com/messages/${selectedChat}`);
        const data = await response.json();
        setChatMessages(data);
      } catch (error) {
        console.error("Failed to fetch messages:", error);
      }
    };

    if (selectedChat) {
      fetchMessages();

      // Set up an interval to fetch new messages every 5 seconds
      const intervalId = setInterval(() => {
        fetchMessages();
      }, 5000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [selectedChat]);

  // Function to handle sending new message
  const handleSendMessage = async (newMessage) => {
    // Send the new message to the backend
    try {
      const response = await fetch('https://api.pmitnetwork.com/messages', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          chat_room: selectedChat,
          user: "You", // or the actual user name
          message: newMessage,
        }),
      });

      if (response.ok) {
        // After sending the message, re-fetch messages
        // fetchMessages();
      } else {
        console.error('Failed to send message');
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <h3>{selectedChat}</h3>
      </div>
      <div className="chat-messages">
        {chatMessages.map((msg, index) => (
          <div
            key={index}
            className={`message ${msg.user === "You" ? "sent" : "received"}`}
          >
            <div className="message-avatar">
              <img src={monkey} alt="User avatar" className="images" />
            </div>
            <div className="message-text-container">
              <div className="message-user">{msg.user}</div>
              <div className="message-text">{msg.message}</div>
              <div className="message-time">{formatDate(msg.timestamp)}</div>
            </div>
          </div>
        ))}
      </div>
      <MessageInput onSendMessage={handleSendMessage} />
    </div>
  );
};

export default ChatWindow;

import React, { useState } from 'react';
import './BusinessSidebar.css';
import { FaHome, FaComments, FaBell, FaFilePdf, FaMusic,FaPhotoVideo, FaFilm, FaTv, FaUser, FaList, FaCog, FaBars } from 'react-icons/fa';
import { FiFile, FiVideo } from 'react-icons/fi';
import newicon from "../images/newicon.svg";
import Homeicon from "../images/Homeicon.svg";
import Chat from "../images/chat.svg";
import Bell from "../images/bell.svg";
import Live from "../images/wifi.svg";
import Profile from "../images/profile.svg";
import Topics from "../images/slash.svg";
import Settings from "../images/settings.svg";
import { useNavigate,Link } from 'react-router-dom';
import Cookies from "js-cookie";


import {  MdPermMedia  } from 'react-icons/md';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate=useNavigate();
 
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const handleLogout = () => {
    Cookies.remove("token");
    navigate("/login");
  }
 
  return (
    <>
      {/* Burger button for mobile */}
      <button className="burger-btn" onClick={toggleSidebar}>
        <FaBars />
      </button>
 
      {/* Sidebar */}
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
         <div className='sidebar-logo-container'>
        <div className='img-sidebar'>

        <img src={newicon} alt="logo" className="logo" />
        {/* <TiMessages className='logo-dimensions-ps'/> */}

        </div>
        <div className='sidebar-heading'>
            <p className='header1'>Put me</p>
            <p className='header2'>in touch</p>
        </div>
        </div>
        
           
      
        <ul className="menu">
        <li>
            <img src={Homeicon} alt="Home" className="menu-icon" /> Home
          </li>
          <li>
            <img src={Chat} alt="Discuss" className="menu-icon" /> Discuss
          </li>
          <li>
    <Link to="/notifications"><img src={Bell} alt="Notifications" className="menu-icon" /> Notifications</Link>
  </li>
  <li >
            <Link to="/busimedia">
              <img src={Live} alt="Media" className="menu-icon" /> Media
            </Link>
          </li>
          <li>
    <Link to="/live"><img src={Live} alt="Live" className="menu-icon" /> Live</Link>
  </li>
          <li>
           <Link to="/businessmainProfile"><img src={Profile} alt="Profile" className="menu-icon" /> Profile</Link> 
          </li>
          <li>
            <img src={Topics} alt="Topics" className="menu-icon" /> Topics
          </li>
          <Link to="/businsettings" style={{ textDecoration: 'none', color: 'inherit' }}>
        <img src={Settings} alt="Settings" className="menu-icon" /> Settings
      </Link>
        </ul>
        {/* Sidebar Buttons - Hide these in desktop view */}
        <div className="sidebar-buttons">
          <button className="get-started"><Link to="/user">Back</Link></button>
          <button className="sign-up" onClick={handleLogout}>logout</button>
        </div>
      </div>
    </>
  );
};
 
export default Sidebar;
// import React, { useState, useEffect } from "react";
// import "./Postmanagement.css";
// import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode"; // Fix import name here
// import axios from "axios";
// import DocumentIcon from "../images/pdf.svg";
// import VideoIcon from "../images/pdf.svg"; // Add a video icon
// import Sidebar from './SideBar'
// const PostManagement = () => {
//   const [activeTab, setActiveTab] = useState("Business"); // Initial active tab
//   const [consumerposts, setConsumerPosts] = useState([]);
//   const navigate = useNavigate();
//   const [username, setUsername] = useState("");
//   const [role, setRole] = useState("");
//   const [businessposts, setBusinessPosts] = useState([]);
//   const [showOptions, setShowOptions] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(""); // To store the selected file (PDF or video)
//   const [fileType, setFileType] = useState(""); // To store the file type ("pdf" or "video")
//   const [optionsPosition, setOptionsPosition] = useState({ top: 0, left: 0 }); // For dropdown position

//   useEffect(() => {
//     const role = localStorage.getItem("role");
//     if (role !== "Superadmin") {
//       navigate("/");
//     }
//   }, [navigate]);

//   useEffect(() => {
//     const token = Cookies.get("token");
//     if (!token) {
//       navigate("/");
//     } else {
//       const decoded = jwtDecode(token);
//       setUsername(decoded.username);
//       setRole(decoded.role);
//       if (decoded.role !== "Superadmin") {
//         navigate("/");
//       }
//     }
//   }, [navigate]);

//   const fetchConsumerPosts = async () => {
//     try {
//       const response = await axios.get(
//         "https://api.pmitnetwork.com/api/getconsumerposts"
//       );
//       setConsumerPosts(response.data);

//       const businessresponse = await axios.get(
//         "https://api.pmitnetwork.com/api/getbusinessposts"
//       );
//       setBusinessPosts(businessresponse.data);
//     } catch (error) {
//       console.error("Error fetching user details:", error);
//     }
//   };

//   useEffect(() => {
//     fetchConsumerPosts();
//   }, []);

//   //   const handleView = () => {
//   //     const url = fileType === "pdf"
//   //       ? `https://api.pmitnetwork.com/uploads/${selectedFile}`
//   //       : `https://api.pmitnetwork.com/uploads/${selectedFile}`;
//   //     window.open(url, "_blank");
//   //     setShowOptions(false); // Close options after action
//   //   };

//   //   const handleView = () => {
//   //     const url = `https://api.pmitnetwork.com/uploads/${selectedFile}`;
//   //     window.open(url, "_blank"); // Open the file in a new tab
//   //     setShowOptions(false); // Close options after action
//   // };

//   // const handleView = () => {
//   //       window.open(`https://api.pmitnetwork.com/uploads/${selectedPdf}`, "_blank");
//   //       setShowOptions(false); // Close options after action
//   //     };

//   const handleView = () => {
//     window.open(`https://api.pmitnetwork.com/uploads/${selectedFile}`, "_blank");
//     setShowOptions(false); // Close options after action
//   };

//   //   const handlingView = () => {
//   //     // Open the selected file in a new tab
//   //     const url = `https://api.pmitnetwork.com/uploads/${selectedFile}`;
//   //     window.open(url, "_blank");
//   //     setShowOptions(false); // Close options after action
//   // };

//   const handleDownload = () => {
//     const link = document.createElement("a");
//     link.href =
//       fileType === "pdf"
//         ? `https://api.pmitnetwork.com/download/${selectedFile}`
//         : `https://api.pmitnetwork.com/download/videos/${selectedFile}`;
//     link.download = selectedFile;
//     link.click();
//     setShowOptions(false); // Close options after action
//   };

//   const handleIconClick = (event, type, file) => {
//     if (showOptions && selectedFile === file) {
//       setShowOptions(false);
//     } else {
//       setShowOptions(true);
//       setSelectedFile(file);
//       setFileType(type); // Store file type ("pdf" or "video")

//       const iconPosition = event.target.getBoundingClientRect();
//       setOptionsPosition({
//         top: iconPosition.bottom + window.scrollY,
//         left: iconPosition.left + window.scrollX,
//       });
//     }
//   };

//   const handleStatusChange = async (postId, status) => {
//     console.log(status);
//     console.log(postId);
//     try {
//       await axios.post("https://api.pmitnetwork.com/api/updatePostStatus", {
//         postId,
//         postStatus: status,
//       });
//       fetchConsumerPosts(); // Refresh the posts after updating status
//     } catch (error) {
//       console.error("Error updating post status:", error);
//     }
//   };
//   return (
//     <div className="home-post-container">
//      <Sidebar />

//     <main className="main-container">
      
//       <div className="container mt-3">
//         <div className="d-flex mb-3 usertabdata">
//           <button
//             className={`tab-consumer ${
//               activeTab === "Consumer" ? "active-userconsumer" : ""
//             }`}
//             onClick={() => setActiveTab("Consumer")}
//           >
//             Consumer
//           </button>
//           <button
//             className={`tab-business ${
//               activeTab === "Business" ? "active-userbusiness" : ""
//             }`}
//             onClick={() => setActiveTab("Business")}
//           >
//             Business
//           </button>
//         </div>

//         {activeTab === "Consumer" && (
//           <div>
//             {consumerposts.map((user) => (
//               <div
//                 key={user.postId}
//                 className="mt-3 border rounded p-3 bg-light"
//               >
//                 <div className="d-flex gap-3">
//                   <div className="userd-icon d-flex justify-content-center align-items-center">
//                     {/* <p className="pt-2">{user.userIconSize}</p> */}
//                   </div>
//                   <div>
//                     <p className="pt-2 userd-name">{user.username}</p>
//                   </div>
//                   {user.pdf && (
//                     <img
//                       src={DocumentIcon}
//                       alt="Document Icon"
//                       className="media-icon"
//                       onClick={(event) =>
//                         handleIconClick(event, "pdf", user.pdf)
//                       }
//                     />
//                   )}
//                   {user.gif && (
//                     <img
//                       src={VideoIcon}
//                       alt="Video Icon"
//                       className="media-icon"
//                       onClick={(event) =>
//                         handleIconClick(event, "video", user.gif)
//                       }
//                     />
//                   )}
//                 </div>
//                 <p className="userp-name mt-3">{user.postques}</p>

//                 {user.images && (
//                   <img
//                     src={`https://api.pmitnetwork.com/uploads/${user.images}`}
//                     alt="Post Image"
//                   />
//                 )}

//                 <div className="mt-3 d-flex gap-3">
//                   <button
//                     className={`btn btn-mdn ${
//                       user.postStatus === "Approve"
//                         ? "disabled-btn"
//                         : "btn-success"
//                     }`}
//                     onClick={() => handleStatusChange(user.postId, "Approve")}
//                     disabled={user.postStatus === "Approve"}
//                   >
//                     Approve
//                   </button>
//                   <button
//                     className={`btn btn-mdn ${
//                       user.postStatus === "DisApprove"
//                         ? "disabled-btn"
//                         : "btn-danger"
//                     }`}
//                     onClick={() =>
//                       handleStatusChange(user.postId, "DisApprove")
//                     }
//                     disabled={user.postStatus === "DisApprove"}
//                   >
//                     Disapprove
//                   </button>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}

//         {activeTab === "Business" && (
//           <div>
//             {businessposts.map((user) => (
//               <div
//                 key={user.postId}
//                 className="mt-3 border rounded p-3 bg-light"
//               >
//                 <div className="d-flex gap-3">
//                   <div className="userd-icon d-flex justify-content-center align-items-center">
//                     <p className="pt-2">{user.userIconSize}</p>
//                   </div>
//                   <div>
//                     <p className="pt-2 userd-name">{user.username}</p>
//                   </div>
//                   {user.pdf && (
//                     <img
//                       src={DocumentIcon}
//                       alt="Document Icon"
//                       className="media-icon"
//                       onClick={(event) =>
//                         handleIconClick(event, "pdf", user.pdf)
//                       }
//                     />
//                   )}
//                   {user.gif && (
//                     <img
//                       src={VideoIcon}
//                       alt="Video Icon"
//                       className="media-icon"
//                       onClick={(event) =>
//                         handleIconClick(event, "video", user.gif)
//                       }
//                     />
//                   )}
//                 </div>
//                 <p className="userp-name mt-3">{user.postques}</p>
//                 {user.images && (
//                   <img
//                     src={`https://api.pmitnetwork.com/uploads/${user.images}`}
//                     alt="Post Image"
//                   />
//                 )}
//                 <div className="mt-3 d-flex gap-3">
//                   <button
//                     className={`btn btn-mdn ${
//                       user.postStatus === "Approve"
//                         ? "disabled-btn"
//                         : "btn-success"
//                     }`}
//                     onClick={() => handleStatusChange(user.postId, "Approve")}
//                     disabled={user.postStatus === "Approve"}
//                   >
//                     Approve
//                   </button>
//                   <button
//                     className={`btn btn-mdn ${
//                       user.postStatus === "DisApprove"
//                         ? "disabled-btn"
//                         : "btn-danger"
//                     }`}
//                     onClick={() =>
//                       handleStatusChange(user.postId, "DisApprove")
//                     }
//                     disabled={user.postStatus === "DisApprove"}
//                   >
//                     Disapprove
//                   </button>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       {/* Document Options Dropdown */}
//       {showOptions && (
//         <div
//           className="options-dropdown"
//           style={{
//             top: `${optionsPosition.top}px`,
//             left: `${optionsPosition.left}px`,
//           }}
//         >
//           <div>
//             <button onClick={handleView}>View</button>
//           </div>
//           <button onClick={handleDownload}>Download</button>
//         </div>
//       )}
//     </main>
//     </div>
//   );
// };

// export default PostManagement;




import React, { useState, useEffect } from "react";
import "./Postmanagement.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode"; 
import axios from "axios";
import Sidebar from './SideBar';

const PostManagement = () => {
  const [activeTab, setActiveTab] = useState("Business");
  const [consumerposts, setConsumerPosts] = useState([]);
  const [businessposts, setBusinessPosts] = useState([]);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [fileType, setFileType] = useState("");
  const [optionsPosition, setOptionsPosition] = useState({ top: 0, left: 0 });
  
  // Pop-up and Post Handling States
  const [showDeclinePopup, setShowDeclinePopup] = useState(false);
  const [currentPostId, setCurrentPostId] = useState(null); // To track which post the pop-up is for
  const [comment, setComment] = useState(""); // To store the comment entered by the user
  
  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "Superadmin") {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "Superadmin") {
        navigate("/");
      }
    }
  }, [navigate]);

  const fetchConsumerPosts = async () => {
    try {
      const response = await axios.get("https://api.pmitnetwork.com/api/getconsumerposts");
      setConsumerPosts(response.data);

      const businessresponse = await axios.get("https://api.pmitnetwork.com/api/getbusinessposts");
      setBusinessPosts(businessresponse.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    fetchConsumerPosts();
  }, []);

  const handleStatusChange = async (postId, status) => {
    try {
      await axios.post("https://api.pmitnetwork.com/api/updatePostStatus", {
        postId,
        postStatus: status,
      });
      fetchConsumerPosts();
    } catch (error) {
      console.error("Error updating post status:", error);
    }
  };

  // Open Decline Pop-up for a given postId
  const handleDeclineClick = (postId) => {
    setCurrentPostId(postId);
    setShowDeclinePopup(true);
  };

  // Close Pop-up
  const handleClosePopup = () => {
    setShowDeclinePopup(false);
    setComment(""); // Clear comment field when closing
    setCurrentPostId(null);
  };

  // Submit Comment (for Decline)
  const handleSubmitComment = async () => {
    try {
      // Send comment to backend if needed
      await axios.post("https://api.pmitnetwork.com/api/submitComment", {
        postId: currentPostId,
        comment: comment,
      });
      // After submitting, change status to Decline
      await handleStatusChange(currentPostId, "Decline");

      // Close the pop-up and reset the states
      handleClosePopup();
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  return (
    <div className="home-post-container">
      <Sidebar />
      <div className="main-content">
        <div className="container-wide mt-3">
          <div className="d-flex mb-3 pm-usertabdata">
            <button
              className={`tab-consumer ${activeTab === "Consumer" ? "active-userconsumer" : ""}`}
              onClick={() => setActiveTab("Consumer")}
            >
              Consumer
            </button>
            <button
              className={`tab-business ${activeTab === "Business" ? "active-userbusiness" : ""}`}
              onClick={() => setActiveTab("Business")}
            >
              Business
            </button>
          </div>

          {/* Consumer Posts */}
          {activeTab === "Consumer" && (
            <div className="pmusers-container">
              {consumerposts.map((user) => (
                <div style={{ backgroundColor: "#ddd" }} key={user.postId} className="mt-3 border rounded p-3 pmuser-card">
                  <div className="d-flex gap-3">
                    <div className="userd-icon d-flex justify-content-center align-items-center">
                      {/* Optional: User icon */}
                    </div>
                    <div>
                      <p className="pt-2 userd-name">{user.username}</p>
                    </div>
                  </div>
                  <p className="userp-name mt-3">{user.postques}</p>
                  {user.images && (
                    <img
                      src={`https://api.pmitnetwork.com/uploads/${user.images}`}
                      alt="Post Image"
                      className="pmuserdiv"
                    />
                  )}
                  <div className="mt-3 d-flex gap-3">
                    <button
                      className={`pm-popup-approve ${user.postStatus === "Approve" ? "disabled-btn" : "btn-success"}`}
                      onClick={() => handleStatusChange(user.postId, "Approve")}
                      disabled={user.postStatus === "Approve"}
                    >
                      Approve
                    </button>
                    <button
                      className={`pm-popup-decline ${user.postStatus === "Decline" ? "disabled-btn" : "btn-danger"}`}
                      onClick={() => handleDeclineClick(user.postId)}
                      disabled={user.postStatus === "Decline"}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Business Posts */}
          {activeTab === "Business" && (
            <div className="pmusers-container">
              {businessposts.map((business) => (
                <div style={{ backgroundColor: "#ddd" }} key={business.postId} className="mt-3 border rounded p-3 pmuser-card">
                  <div className="d-flex gap-3">
                    <div className="userd-icon d-flex justify-content-center align-items-center">
                      {/* Optional: Business icon */}
                    </div>
                    <div>
                      <p className="pt-2 userd-name">{business.username}</p>
                    </div>
                  </div>
                  <p className="userp-name mt-3">{business.postques}</p>
                  {business.images && (
                    <img
                      src={`https://api.pmitnetwork.com/uploads/${business.images}`}
                      alt="Business Image"
                      className="pmuserdiv"
                    />
                  )}
                  <div className="mt-3 d-flex gap-3">
                    <button
                      className={`pm-popup-approve ${business.postStatus === "Approve" ? "disabled-btn" : "btn-success"}`}
                      onClick={() => handleStatusChange(business.postId, "Approve")}
                      disabled={business.postStatus === "Approve"}
                    >
                      Approve
                    </button>
                    <button
                      className={`pm-popup-decline ${business.postStatus === "Decline" ? "disabled-btn" : "btn-danger"}`}
                      onClick={() => handleDeclineClick(business.postId)}
                      disabled={business.postStatus === "Decline"}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Decline Comment Popup */}
      {showDeclinePopup && currentPostId && (
        <div className="pm-popup-overlay">
          <div className="pm-popup-box">
            <h3 className="pm-popup-heading">Reason</h3>
            <textarea
              className="pm-popup-textarea"
              placeholder="Add your comment here..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <div className="pm-popup-buttons">
              <button className="pm-popup-btn-cancel" onClick={handleClosePopup}>
                Cancel
              </button>
              <button className="pm-popup-btn-submit" onClick={handleSubmitComment}>
                Submit Reason
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostManagement;

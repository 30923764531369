import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Tab,
  Tabs,
  Paper,
  FormControlLabel,
  Switch,
  Button,
  Divider,
  TextField,
  Checkbox,
} from '@mui/material';
import SideBar from './SideBar';

// Main Settings Module
const SettingsModule = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [darkMode, setDarkMode] = useState(false);
  const [receiveEmails, setReceiveEmails] = useState(true);
  const [profileDetails, setProfileDetails] = useState({
    username: '',
    email: '',
    phone: '',
  });

  // Handle tab changes
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  // Handle dark mode toggle
  const handleToggleDarkMode = (event) => {
    setDarkMode(event.target.checked);
    document.body.style.backgroundColor = event.target.checked ? '#121212' : '#fff';
  };

  // Handle form changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileDetails({
      ...profileDetails,
      [name]: value,
    });
  };

  // Handle email notifications checkbox
  const handleEmailChange = (e) => {
    setReceiveEmails(e.target.checked);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: darkMode ? '#121212' : '#f5f5f5' }}>
      {/* Sidebar */}
      <Box sx={{ width: '300px', borderRight: '1px solid #ccc', backgroundColor: darkMode ? '#1d1d1d' : '#fff' }}>
        <SideBar />
      </Box>

      {/* Settings Content */}
      <Box sx={{ flex: 1, padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Settings
        </Typography>

        <Paper sx={{ padding: '20px' }}>
          <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth" sx={{ marginBottom: '20px' }}>
            <Tab label="Display Settings" />
            <Tab label="Privacy & Security" />
            <Tab label="Profile Settings" />
            <Tab label="Account Settings" />
          </Tabs>

          {/* Tab Content */}
          <Box hidden={tabIndex !== 0}>
            <Typography variant="h6">Display Settings</Typography>
            <Divider sx={{ marginY: '10px' }} />
            <FormControlLabel
              control={<Switch checked={darkMode} onChange={handleToggleDarkMode} />}
              label="Dark Mode"
            />
            <Typography variant="body2" sx={{ marginTop: '10px' }}>
              Enable Dark Mode to reduce eye strain in low light environments.
            </Typography>
          </Box>

          <Box hidden={tabIndex !== 1}>
            <Typography variant="h6">Privacy & Security</Typography>
            <Divider sx={{ marginY: '10px' }} />
            <Typography variant="body1" sx={{ marginBottom: '10px' }}>
              Protect your account by managing your privacy and security settings here.
            </Typography>

            <FormControlLabel
              control={<Checkbox checked={receiveEmails} onChange={handleEmailChange} />}
              label="Receive Email Notifications"
            />
            <Typography variant="body2" sx={{ marginTop: '10px' }}>
              Stay updated with email notifications about your account activities.
            </Typography>

            <Button variant="contained" color="primary" sx={{ width: '100%', marginTop: '20px' }}>
              Update Privacy Settings
            </Button>
          </Box>

          <Box hidden={tabIndex !== 2}>
            <Typography variant="h6">Profile Settings</Typography>
            <Divider sx={{ marginY: '10px' }} />
            <Typography variant="body1" sx={{ marginBottom: '10px' }}>
              Update your personal information here.
            </Typography>

            <TextField
              label="Username"
              name="username"
              value={profileDetails.username}
              onChange={handleInputChange}
              fullWidth
              sx={{ marginBottom: '15px' }}
            />
            <TextField
              label="Email Address"
              name="email"
              value={profileDetails.email}
              onChange={handleInputChange}
              fullWidth
              sx={{ marginBottom: '15px' }}
            />
            <TextField
              label="Phone Number"
              name="phone"
              value={profileDetails.phone}
              onChange={handleInputChange}
              fullWidth
              sx={{ marginBottom: '15px' }}
            />

            <Button variant="contained" color="primary" sx={{ width: '100%' }}>
              Save Profile Changes
            </Button>
          </Box>

          <Box hidden={tabIndex !== 3}>
            <Typography variant="h6">Account Settings</Typography>
            <Divider sx={{ marginY: '10px' }} />
            <Typography variant="body1" sx={{ marginBottom: '10px' }}>
              Manage your account settings, including closing your account permanently.
            </Typography>

            <Button variant="contained" color="error" sx={{ width: '100%', marginBottom: '20px' }}>
              Close Account
            </Button>

            <Typography variant="body2" sx={{ marginBottom: '10px', color: 'red' }}>
              Closing your account will permanently remove all your data. Please proceed with caution.
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default SettingsModule;

import React from "react";
import "./Secondfooter.css";

const Secondfooter = () => {
  return (
    <div className="secondfooter-part-main-co">
    <div className="secondfooter-part-content-part">
      <div className="secondfooter-part1">
         <p className="copyright-content"><span>&copy;</span>2024 Put me in touch LLC.All Rights Reserverd</p>
      </div>
      <div className="secondfooter-part2">
        <p>
          Put Me in Touch(PMIT) is a cutting-edge B2B social platform designed
          to connect business, foster collaborations, and drive growth. Our
          mission is to simplify networking by matching you with the right
          partners , clients , and opportunities ,  empowering your business to
          succeed. Join us to experience  seamless communication, tailored
          connections, and limitless potential.
        </p>
      </div>
      <hr size="10"/>
      
      <div className="secondfooter-part3">
        <p>
          For any queries or grievances, Please write to us at
          <strong> support@pmit.com</strong>
        </p>
      </div>
    </div>
    </div>
  );
};

export default Secondfooter;

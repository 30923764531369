// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Card,
//   CardContent,
//   CardActions,
//   Typography,
//   Button,
//   Grid,
//   TextField,
// } from '@mui/material';
// import { useLocation, useNavigate } from 'react-router-dom';
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
// import axios from "axios";


// const UploadSection = ({ registereddId }) => {
//   const location = useLocation();
//   const [registeredId, setRegisteredId] = useState(null);
//   const [grabedid, setGrabedid] = useState(null); // The variable to store the value
//   const [username, setUsername] = useState("");
//   const [role, setRole] = useState("");
//   const navigate = useNavigate();
//   const [userDetails, setUserDetails] = useState({});



//   useEffect(() => {
//     const role = localStorage.getItem("role");
//     if (role !== "user") {
//       navigate("/");
//     }
//   }, [navigate]);

//   useEffect(() => {
//     const token = Cookies.get("token");
//     if (!token) {
//       navigate("/");
//     } else {
//       const decoded = jwtDecode(token);
//       setUsername(decoded.username);
//       setRole(decoded.role);
//       if (decoded.role !== "user") {
//         navigate("/");
//       }
//     }
//   }, [navigate]);



//   useEffect(() => {
//     const params = new URLSearchParams(location.search);
//     const id = params.get('registeredId');
//     if (id) {
//       setRegisteredId(id); // Set the registeredId from the query parameter
//     }
//   }, [location]);
//   console.log("proofssectioni",registeredId);
//   console.log("directtabections",registereddId)


//   useEffect(() => {
//     // Log both variables to check their values
//     console.log("proofssectioni", registeredId);
//     console.log("directtabections", registereddId);

//     // Check if registeredId has a value, then assign it to grabedid
//     if (registeredId) {
//       setGrabedid(registeredId);
//     } else if (registereddId) {
//       setGrabedid(registereddId);
//     } else {
//       setGrabedid(null); // If neither has a value, set grabedid to null
//     }

//     // Log grabedid to see the result
//   }, [registeredId, registereddId]); 
//   console.log("grabedid:", grabedid);


//   useEffect(() => {
//     const fetchUserDetails = async () => {
//       try {
//         const response = await axios.get(
//           `https://api.pmitnetwork.com/api/getuserdetails/afterloginwithid/${grabedid}`
//         );
//         setUserDetails(response.data[0]);
//       } catch (error) {
//         console.error("Error fetching user details:", error);
//       }
//     };

//     if (grabedid) {
//       fetchUserDetails();
//     }
//   }, [grabedid]);

//   console.log("grabed details in proofs", userDetails);


//   const sections = [
//     {
//       title: 'Business Photos',
//       description: 'Photos are an important part of your profile.',
//       buttonText: 'Upload Photos',
//       fieldName: 'businessPhotos',
//     },
//     {
//       title: 'Business Documents',
//       description: 'Documents help us verify and approve your profile faster.',
//       buttonText: 'Upload Documents',
//       fieldName: 'businessDocuments',
//     },
//     {
//       title: 'Proof of Business',
//       description: 'Proof of your business operations and registration.',
//       buttonText: 'Upload Proof',
//       fieldName: 'proofOfBusiness',
//     },
//   ];

//   const [fileInputs, setFileInputs] = useState({
//     businessPhotos: '',
//     businessDocuments: '',
//     proofOfBusiness: '',
//     aadhaarFile: '',
//     panFile: '',
//   });

//   const [aadhaar, setAadhaar] = useState('');
//   const [pan, setPan] = useState('');

//   const handleFileChange = (event, fieldName) => {
//     setFileInputs({
//       ...fileInputs,
//       [fieldName]: event.target.files[0],
//     });
//   };

//   const handleSubmit = (fieldName) => {
//     console.log(`Submitting file for ${fieldName}:`, fileInputs[fieldName]);
//     setFileInputs({ ...fileInputs, [fieldName]: '' });
//   };

//   const handleContinue = () => {
//     console.log('Continue to the next step');
//     // Add your logic to go to the next step
//   };

//   const isContinueEnabled = Object.values(fileInputs).some((file) => file);

//   return (
//     <Box sx={{ padding: 2 }}>
//       <Typography variant="h5" sx={{ mb: 2 }}>
//         Photos, Documents & Proof
//       </Typography>

//       <Grid container spacing={2} sx={{ mb: 3 }}>
//         <Grid item xs={6} sm={4}>
//           <TextField
//             label="Aadhaar Number"
//             value={aadhaar}
//             onChange={(e) => setAadhaar(e.target.value)}
//             fullWidth
//             variant="outlined"
//             sx={{ maxWidth: 300 }}
//           />
//           <TextField
//             type="file"
//             onChange={(event) => handleFileChange(event, 'aadhaarFile')}
//             fullWidth
//             variant="outlined"
//             sx={{ mt: 1, maxWidth: 300 }}
//           />
//         </Grid>
//         <Grid item xs={6} sm={4}>
//           <TextField
//             label="PAN Number"
//             value={pan}
//             onChange={(e) => setPan(e.target.value)}
//             fullWidth
//             variant="outlined"
//             sx={{ maxWidth: 300 }}
//           />
//           <TextField
//             type="file"
//             onChange={(event) => handleFileChange(event, 'panFile')}
//             fullWidth
//             variant="outlined"
//             sx={{ mt: 1, maxWidth: 300 }}
//           />
//         </Grid>
//       </Grid>

//       <Grid container spacing={2}>
//         {sections.map((section, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Card sx={{ padding: 2 }}>
//               <CardContent>
//                 <Typography variant="h6" gutterBottom>
//                   {section.title}
//                 </Typography>
//                 <Typography variant="body2" sx={{ mb: 2 }}>
//                   {section.description}
//                 </Typography>
//                 <TextField
//                   type="file"
//                   onChange={(event) => handleFileChange(event, section.fieldName)}
//                   fullWidth
//                   variant="outlined"
//                   sx={{ mb: 1, maxWidth: 300 }}
//                 />
//               </CardContent>
//               <CardActions>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={() => handleSubmit(section.fieldName)}
//                   disabled={!fileInputs[section.fieldName]}
//                 >
//                   {section.buttonText}
//                 </Button>
//               </CardActions>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>
//       <Box sx={{ textAlign: 'center', mt: 4 }}>
      
//       <Button
//         variant="contained"
//         color="secondary"
//         onClick={handleContinue}
//         disabled={!isContinueEnabled}
//       >
//         Save
//       </Button>
//     </Box>

//       <Box sx={{ textAlign: 'center', mt: 4 }}>
      
//         <Button
//           variant="contained"
//           color="secondary"
//           onClick={handleContinue}
//           disabled={!isContinueEnabled}
//         >
//           Continue
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default UploadSection;


















// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Card,
//   CardContent,
//   CardActions,
//   Typography,
//   Button,
//   Grid,
//   TextField,
// } from '@mui/material';
// import { useLocation, useNavigate } from 'react-router-dom';
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
// import axios from "axios";

// const UploadSection = ({ registereddId }) => {
//   const location = useLocation();
//   const [registeredId, setRegisteredId] = useState(null);
//   const [grabedid, setGrabedid] = useState(null);
//   const [username, setUsername] = useState("");
//   const [role, setRole] = useState("");
//   const navigate = useNavigate();
//   const [userDetails, setUserDetails] = useState({
//     aadhaarNumber: '',
//     panNumber: '',
//     addharimage: '',
//     pannumberimage: '',
//     businessPhotos: '',
//     businessDocuments: '',
//     proofOfBusiness: ''
//   });

//   useEffect(() => {
//     const role = localStorage.getItem("role");
//     if (role !== "user") {
//       navigate("/");
//     }
//   }, [navigate]);

//   useEffect(() => {
//     const token = Cookies.get("token");
//     if (!token) {
//       navigate("/");
//     } else {
//       const decoded = jwtDecode(token);
//       setUsername(decoded.username);
//       setRole(decoded.role);
//       if (decoded.role !== "user") {
//         navigate("/");
//       }
//     }
//   }, [navigate]);

//   useEffect(() => {
//     const params = new URLSearchParams(location.search);
//     const id = params.get('registeredId');
//     if (id) {
//       setRegisteredId(id);
//     }
//   }, [location]);

//   useEffect(() => {
//     if (registeredId || registereddId) {
//       const finalId = registeredId || registereddId;
//       setGrabedid(finalId);
//       fetchUserDetails(finalId);
//     }
//   }, [registeredId, registereddId]);

//   const fetchUserDetails = async (id) => {
//     try {
//       const response = await axios.get(`https://api.pmitnetwork.com/api/getuserdetails/afterloginwithid/${id}`);
//       const data = response.data[0];
//       setUserDetails({
//         ...data,
//         aadhaarNumber: data.aadhaarNumber || '',
//         panNumber: data.panNumber || '',
//         addharimage: data.addharimage || '',
//         pannumberimage: data.pannumberimage || '',
//         businessPhotos: data.businessPhotos || '',
//         businessDocuments: data.businessDocuments || '',
//         proofOfBusiness: data.proofOfBusiness || ''
//       });
//     } catch (error) {
//       console.error("Error fetching user details:", error);
//     }
//   };

//   const handleInputChange = (e, field) => {
//     setUserDetails({
//       ...userDetails,
//       [field]: e.target.value
//     });
//   };

//   const handleFileChange = (event, fieldName) => {
//     setUserDetails({
//       ...userDetails,
//       [fieldName]: event.target.files[0]
//     });
//   };

//   const handleSubmit = async (fieldName) => {
//     const file = userDetails[fieldName];
//     if (file) {
//       const formData = new FormData();
//       formData.append(fieldName, file);
//       try {
//         await axios.post('https://api.pmitnetwork.com/api/uploadFile', formData);
//         alert(`${fieldName} uploaded successfully`);
//         setUserDetails({ ...userDetails, [fieldName]: '' });
//       } catch (error) {
//         console.error("Error uploading file:", error);
//       }
//     }
//   };

//   const handleUpdateDetails = async () => {
//     try {
//       const response = await axios.put(`https://api.pmitnetwork.com/api/updateuserdetails/${grabedid}`, userDetails);
//       alert('User details updated successfully');
//     } catch (error) {
//       console.error("Error updating user details:", error);
//     }
//   };

//   const sections = [
//     {
//       title: 'Business Photos',
//       description: 'Photos are an important part of your profile.',
//       buttonText: 'Upload Photos',
//       fieldName: 'businessPhotos',
//     },
//     {
//       title: 'Business Documents',
//       description: 'Documents help us verify and approve your profile faster.',
//       buttonText: 'Upload Documents',
//       fieldName: 'businessDocuments',
//     },
//     {
//       title: 'Proof of Business',
//       description: 'Proof of your business operations and registration.',
//       buttonText: 'Upload Proof',
//       fieldName: 'proofOfBusiness',
//     },
//   ];

//   return (
//     <Box sx={{ padding: 2 }}>
//       <Typography variant="h5" sx={{ mb: 2 }}>
//         Photos, Documents & Proof
//       </Typography>

//       <Grid container spacing={2} sx={{ mb: 3 }}>
//         <Grid item xs={6} sm={4}>
//           <TextField
//             label="Aadhaar Number"
//             value={userDetails.aadhaarNumber}
//             onChange={(e) => handleInputChange(e, 'aadhaarNumber')}
//             fullWidth
//             variant="outlined"
//             sx={{ maxWidth: 300 }}
//           />
//           <TextField
//             type="file"
//             onChange={(event) => handleFileChange(event, 'aadhaarFile')}
//             fullWidth
//             variant="outlined"
//             sx={{ mt: 1, maxWidth: 300 }}
//           />
//         </Grid>
//         <Grid item xs={6} sm={4}>
//           <TextField
//             label="PAN Number"
//             value={userDetails.panNumber}
//             onChange={(e) => handleInputChange(e, 'panNumber')}
//             fullWidth
//             variant="outlined"
//             sx={{ maxWidth: 300 }}
//           />
//           <TextField
//             type="file"
//             onChange={(event) => handleFileChange(event, 'panFile')}
//             fullWidth
//             variant="outlined"
//             sx={{ mt: 1, maxWidth: 300 }}
//           />
//         </Grid>
//       </Grid>

//       <Grid container spacing={2}>
//         {sections.map((section, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Card sx={{ padding: 2 }}>
//               <CardContent>
//                 <Typography variant="h6" gutterBottom>
//                   {section.title}
//                 </Typography>
//                 <Typography variant="body2" sx={{ mb: 2 }}>
//                   {section.description}
//                 </Typography>
//                 <TextField
//                   type="file"
//                   onChange={(event) => handleFileChange(event, section.fieldName)}
//                   fullWidth
//                   variant="outlined"
//                   sx={{ mb: 1, maxWidth: 300 }}
//                 />
//               </CardContent>
//               <CardActions>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={() => handleSubmit(section.fieldName)}
//                   disabled={!userDetails[section.fieldName]}
//                 >
//                   {section.buttonText}
//                 </Button>
//               </CardActions>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>

//       <Box sx={{ textAlign: 'center', mt: 4 }}>
//         <Button
//           variant="contained"
//           color="secondary"
//           onClick={handleUpdateDetails}
//         >
//           Save Details
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default UploadSection;




// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Card,
//   CardContent,
//   CardActions,
//   Typography,
//   Button,
//   Grid,
//   TextField,
// } from '@mui/material';
// import { useLocation, useNavigate } from 'react-router-dom';
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
// import axios from "axios";

// const UploadSection = ({ registereddId }) => {
//   const location = useLocation();
//   const [registeredId, setRegisteredId] = useState(null);
//   const [grabedid, setGrabedid] = useState(null);
//   const [username, setUsername] = useState("");
//   const [role, setRole] = useState("");
//   const navigate = useNavigate();
//   const [userDetails, setUserDetails] = useState({
//     aadhaarNumber: '',
//     panNumber: '',
//     aadhaarFile: null,
//     panFile: null,
//     businessPhotos: null,
//     businessDocuments: null,
//     proofOfBusiness: null,
//   });

//   useEffect(() => {
//     const role = localStorage.getItem("role");
//     if (role !== "user") {
//       navigate("/");
//     }
//   }, [navigate]);

//   useEffect(() => {
//     const token = Cookies.get("token");
//     if (!token) {
//       navigate("/");
//     } else {
//       const decoded = jwtDecode(token);
//       setUsername(decoded.username);
//       setRole(decoded.role);
//       if (decoded.role !== "user") {
//         navigate("/");
//       }
//     }
//   }, [navigate]);

//   useEffect(() => {
//     const params = new URLSearchParams(location.search);
//     const id = params.get('registeredId');
//     if (id) {
//       setRegisteredId(id);
//     }
//   }, [location]);

//   useEffect(() => {
//     if (registeredId || registereddId) {
//       const finalId = registeredId || registereddId;
//       setGrabedid(finalId);
//       fetchUserDetails(finalId);
//     }
//   }, [registeredId, registereddId]);

//   const fetchUserDetails = async (id) => {
//     try {
//       const response = await axios.get(`https://api.pmitnetwork.com/api/getuserdetails/afterloginwithid/${id}`);
//       const data = response.data[0];
//       setUserDetails({
//         aadhaarNumber: data.aadhaarNumber || '',
//         panNumber: data.panNumber || '',
//         aadhaarFile: data.aadhaarFile || null,
//         panFile: data.panFile || null,
//         businessPhotos: data.businessPhotos || null,
//         businessDocuments: data.businessDocuments || null,
//         proofOfBusiness: data.proofOfBusiness || null,
//       });
//     } catch (error) {
//       console.error("Error fetching user details:", error);
//     }
//   };

//   const handleInputChange = (e, field) => {
//     setUserDetails({
//       ...userDetails,
//       [field]: e.target.value
//     });
//   };

//   const handleFileChange = (event, fieldName) => {
//     setUserDetails({
//       ...userDetails,
//       [fieldName]: event.target.files[0]
//     });
//   };

//   const handleSaveDetails = async () => {
//     const formData = new FormData();
//     // Append text fields
//     formData.append('aadhaarNumber', userDetails.aadhaarNumber);
//     formData.append('panNumber', userDetails.panNumber);

//     // Append files
//     if (userDetails.aadhaarFile) formData.append('aadhaarFile', userDetails.aadhaarFile);
//     if (userDetails.panFile) formData.append('panFile', userDetails.panFile);
//     if (userDetails.businessPhotos) formData.append('businessPhotos', userDetails.businessPhotos);
//     if (userDetails.businessDocuments) formData.append('businessDocuments', userDetails.businessDocuments);
//     if (userDetails.proofOfBusiness) formData.append('proofOfBusiness', userDetails.proofOfBusiness);

//     try {
//       await axios.put(`https://api.pmitnetwork.com/api/updateuserdetails/${grabedid}`, formData);
//       alert('User details updated successfully');
//     } catch (error) {
//       console.error("Error updating user details:", error);
//     }
//   };

//   return (
//     <Box sx={{ padding: 2 }}>
//       <Typography variant="h5" sx={{ mb: 2 }}>
//         Photos, Documents & Proof
//       </Typography>

//       <Grid container spacing={2} sx={{ mb: 3 }}>
//         <Grid item xs={6} sm={4}>
//           <TextField
//             label="Aadhaar Number"
//             value={userDetails.aadhaarNumber}
//             onChange={(e) => handleInputChange(e, 'aadhaarNumber')}
//             fullWidth
//             variant="outlined"
//             sx={{ maxWidth: 300 }}
//           />
//           <TextField
//             type="file"
//             onChange={(event) => handleFileChange(event, 'aadhaarFile')}
//             fullWidth
//             variant="outlined"
//             sx={{ mt: 1, maxWidth: 300 }}
//           />
//         </Grid>
//         <Grid item xs={6} sm={4}>
//           <TextField
//             label="PAN Number"
//             value={userDetails.panNumber}
//             onChange={(e) => handleInputChange(e, 'panNumber')}
//             fullWidth
//             variant="outlined"
//             sx={{ maxWidth: 300 }}
//           />
//           <TextField
//             type="file"
//             onChange={(event) => handleFileChange(event, 'panFile')}
//             fullWidth
//             variant="outlined"
//             sx={{ mt: 1, maxWidth: 300 }}
//           />
//         </Grid>
//       </Grid>

//       <Grid container spacing={2}>
//         {/* Render dynamic sections */}
//         {['businessPhotos', 'businessDocuments', 'proofOfBusiness'].map((field, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Card sx={{ padding: 2 }}>
//               <CardContent>
//                 <Typography variant="h6" gutterBottom>
//                   {field.replace(/([A-Z])/g, ' $1').toUpperCase()}
//                 </Typography>
//                 <TextField
//                   type="file"
//                   onChange={(event) => handleFileChange(event, field)}
//                   fullWidth
//                   variant="outlined"
//                   sx={{ mb: 1, maxWidth: 300 }}
//                 />
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>

//       <Box sx={{ textAlign: 'center', mt: 4 }}>
//         <Button
//           variant="contained"
//           color="secondary"
//           onClick={handleSaveDetails}
//         >
//           Save Details
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default UploadSection;


// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Card,
//   CardContent,
//   Typography,
//   Button,
//   Grid,
//   TextField,
// } from '@mui/material';
// import { useLocation, useNavigate } from 'react-router-dom';
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
// import axios from "axios";

// const UploadSection = ({ registereddId }) => {
//   const location = useLocation();
//   const [registeredId, setRegisteredId] = useState(null);
//   const [grabedid, setGrabedid] = useState(null);
//   const [username, setUsername] = useState("");
//   const [role, setRole] = useState("");
//   const navigate = useNavigate();
//   const [userDetails, setUserDetails] = useState({
//     aadhaarNumber: '',
//     panNumber: '',
//     aadhaarFile: null,
//     panFile: null,
//     businessPhotos: null,
//     businessDocuments: null,
//     proofOfBusiness: null,
//   });

//   useEffect(() => {
//     const role = localStorage.getItem("role");
//     if (role !== "user") {
//       navigate("/");
//     }
//   }, [navigate]);

//   useEffect(() => {
//     const token = Cookies.get("token");
//     if (!token) {
//       navigate("/");
//     } else {
//       const decoded = jwtDecode(token);
//       setUsername(decoded.username);
//       setRole(decoded.role);
//       if (decoded.role !== "user") {
//         navigate("/");
//       }
//     }
//   }, [navigate]);

//   useEffect(() => {
//     const params = new URLSearchParams(location.search);
//     const id = params.get('registeredId');
//     if (id) {
//       setRegisteredId(id);
//     }
//   }, [location]);

//   useEffect(() => {
//     if (registeredId || registereddId) {
//       const finalId = registeredId || registereddId;
//       setGrabedid(finalId);
//       fetchUserDetails(finalId);
//     }
//   }, [registeredId, registereddId]);

//   const fetchUserDetails = async (id) => {
//     try {
//       const response = await axios.get(`https://api.pmitnetwork.com/api/getuserdetails/afterloginwithid/${id}`);
//       const data = response.data[0];
//       setUserDetails({
//         aadhaarNumber: data.aadhaarNumber || '',
//         panNumber: data.panNumber || '',
//         aadhaarFile: data.aadhaarFile || null,
//         panFile: data.panFile || null,
//         businessPhotos: data.businessPhotos || null,
//         businessDocuments: data.businessDocuments || null,
//         proofOfBusiness: data.proofOfBusiness || null,
//       });
//     } catch (error) {
//       console.error("Error fetching user details:", error);
//     }
//   };

//   const handleInputChange = (e, field) => {
//     setUserDetails({
//       ...userDetails,
//       [field]: e.target.value
//     });
//   };

//   const handleFileChange = (event, fieldName) => {
//     setUserDetails({
//       ...userDetails,
//       [fieldName]: event.target.files[0]
//     });
//   };

//   // const handleSaveDetails = async () => {
//   //   const formData = new FormData();
//   //   // Append text fields
//   //   formData.append('aadhaarNumber', userDetails.aadhaarNumber);
//   //   formData.append('panNumber', userDetails.panNumber);

//   //   // Append files (existing or new)
//   //   ['aadhaarFile', 'panFile', 'businessPhotos', 'businessDocuments', 'proofOfBusiness'].forEach(field => {
//   //     if (userDetails[field]) {
//   //       formData.append(field, userDetails[field]);
//   //     }
//   //   });

//   //   try {
//   //     await axios.put(`https://api.pmitnetwork.com/api/updateuserdetails/${grabedid}`, formData);
//   //     alert('User details updated successfully');
//   //   } catch (error) {
//   //     console.error("Error updating user details:", error);
//   //   }
//   // };


//   const handleSaveDetails = async () => {
//     const formData = new FormData();
  
//     // Append text fields
//     formData.append('aadhaarNumber', userDetails.aadhaarNumber);
//     formData.append('panNumber', userDetails.panNumber);
  
//     // Append files (existing or new)
//     ['aadhaarFile', 'panFile', 'businessPhotos', 'businessDocuments', 'proofOfBusiness'].forEach((field) => {
//       if (userDetails[field] instanceof File) {
//         // New file uploaded
//         formData.append(field, userDetails[field]);
//       } else if (userDetails[field]) {
//         // Existing file reference (backend should handle this case)
//         formData.append(field, userDetails[field]);
//       }
//     });
  
//     try {
//       await axios.put(`https://api.pmitnetwork.com/api/updateuserdetails/${grabedid}`, formData);
//       alert('User details updated successfully');
//     } catch (error) {
//       console.error("Error updating user details:", error);
//     }
//   };
  

//   return (
//     <Box sx={{ padding: 2 }}>
//       <Typography variant="h5" sx={{ mb: 2 }}>
//         Photos, Documents & Proof
//       </Typography>

//       <Grid container spacing={2} sx={{ mb: 3 }}>
//         <Grid item xs={6} sm={4}>
//           <TextField
//             label="Aadhaar Number"
//             value={userDetails.aadhaarNumber}
//             onChange={(e) => handleInputChange(e, 'aadhaarNumber')}
//             fullWidth
//             variant="outlined"
//             sx={{ maxWidth: 300 }}
//           />
//           <TextField
//             type="file"
//             onChange={(event) => handleFileChange(event, 'aadhaarFile')}
//             fullWidth
//             variant="outlined"
//             sx={{ mt: 1, maxWidth: 300 }}
//           />
//           {userDetails.aadhaarFile && (
//             <Typography variant="body2" color="textSecondary">
//               {userDetails.aadhaarFile.name || "Previously uploaded Aadhaar file"}
//             </Typography>
//           )}
//         </Grid>
//         <Grid item xs={6} sm={4}>
//           <TextField
//             label="PAN Number"
//             value={userDetails.panNumber}
//             onChange={(e) => handleInputChange(e, 'panNumber')}
//             fullWidth
//             variant="outlined"
//             sx={{ maxWidth: 300 }}
//           />
//           <TextField
//             type="file"
//             onChange={(event) => handleFileChange(event, 'panFile')}
//             fullWidth
//             variant="outlined"
//             sx={{ mt: 1, maxWidth: 300 }}
//           />
//           {userDetails.panFile && (
//             <Typography variant="body2" color="textSecondary">
//               {userDetails.panFile.name || "Previously uploaded PAN file"}
//             </Typography>
//           )}
//         </Grid>
//       </Grid>

//       <Grid container spacing={2}>
//         {/* Render dynamic sections */}
//         {['businessPhotos', 'businessDocuments', 'proofOfBusiness'].map((field, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Card sx={{ padding: 2 }}>
//               <CardContent>
//                 <Typography variant="h6" gutterBottom>
//                   {field.replace(/([A-Z])/g, ' $1').toUpperCase()}
//                 </Typography>
//                 <TextField
//                   type="file"
//                   onChange={(event) => handleFileChange(event, field)}
//                   fullWidth
//                   variant="outlined"
//                   sx={{ mb: 1, maxWidth: 300 }}
//                 />
//                 {userDetails[field] && (
//                   <Typography variant="body2" color="textSecondary">
//                     {userDetails[field].name || "Previously uploaded file"}
//                   </Typography>
//                 )}
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>

//       <Box sx={{ textAlign: 'center', mt: 4 }}>
//         <Button
//           variant="contained"
//           color="secondary"
//           onClick={handleSaveDetails}
//         >
//           Save Details
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default UploadSection;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Card,
  CardContent,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';

const Busproofs = ({ registereddId }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [registeredId, setRegisteredId] = useState(null);
  const [grabedid, setGrabedid] = useState(null);
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [userDetails, setUserDetails] = useState({
    aadhaarNumber: '',
    panNumber: '',
    aadhaarFile: null,
    panFile: null,
    businessPhotos: null,
    businessDocuments: null,
    proofOfBusiness: null,
  });

  // Redirect if role is not 'user'
  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role !== 'user') {
      navigate('/');
    }
  }, [navigate]);

  // Decode token and set user details
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'user') {
        navigate('/');
      }
    }
  }, [navigate]);

  // Grab registeredId from query params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('registeredId');
    if (id) {
      setRegisteredId(id);
    }
  }, [location]);

  // Set grabbedId and fetch user details
  useEffect(() => {
    const finalId = registeredId || registereddId;
    if (finalId) {
      setGrabedid(finalId);
      fetchUserDetails(finalId);
    }
  }, [registeredId, registereddId]);

  // Fetch user details from the server
  const fetchUserDetails = async (id) => {
    try {
      const response = await axios.get(
        `https://api.pmitnetwork.com/api/getuserdetails/afterloginwithid/${id}`
      );
      const data = response.data[0];
      setUserDetails({
        aadhaarNumber: data.aadhaarNumber || '',
        panNumber: data.panNumber || '',
        aadhaarFile: data.aadhaarFile || null,
        panFile: data.panFile || null,
        businessPhotos: data.businessPhotos || null,
        businessDocuments: data.businessDocuments || null,
        proofOfBusiness: data.proofOfBusiness || null,
      });
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  // Handle input changes for text fields
  const handleInputChange = (e, field) => {
    setUserDetails({
      ...userDetails,
      [field]: e.target.value,
    });
  };

  const handleContinue = () => {
    navigate(`/businessprofile?section=Business&registeredId=${grabedid}`); // Navigate to the "Proofs" tab
  };

  // Handle file input changes
  const handleFileChange = (event, fieldName) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: event.target.files[0] || prevDetails[fieldName], // Keep existing file if no new file is selected
    }));
  };

  // Save details to the server
  const handleSaveDetails = async () => {
    const formData = new FormData();

    // Append text fields
    formData.append('aadhaarNumber', userDetails.aadhaarNumber);
    formData.append('panNumber', userDetails.panNumber);

    // Append files (new or existing)
    ['aadhaarFile', 'panFile', 'businessPhotos', 'businessDocuments', 'proofOfBusiness'].forEach((field) => {
      if (userDetails[field] instanceof File) {
        formData.append(field, userDetails[field]); // New file
      } else if (userDetails[field]) {
        formData.append(field, userDetails[field]); // Existing file reference
      }
    });

    try {
      await axios.put(
        `https://api.pmitnetwork.com/api/updateuserdetails/${grabedid}`,
        formData
      );
      alert('User details updated successfully');
      fetchUserDetails();
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Photos, Documents & Proof
      </Typography>

      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Aadhaar Number"
            value={userDetails.aadhaarNumber}
            onChange={(e) => handleInputChange(e, 'aadhaarNumber')}
            fullWidth
            variant="outlined"
            sx={{ maxWidth: 300 }}
          />
          <TextField
            type="file"
            onChange={(event) => handleFileChange(event, 'aadhaarFile')}
            fullWidth
            variant="outlined"
            sx={{ mt: 1, maxWidth: 300 }}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="PAN Number"
            value={userDetails.panNumber}
            onChange={(e) => handleInputChange(e, 'panNumber')}
            fullWidth
            variant="outlined"
            sx={{ maxWidth: 300 }}
          />
          <TextField
            type="file"
            onChange={(event) => handleFileChange(event, 'panFile')}
            fullWidth
            variant="outlined"
            sx={{ mt: 1, maxWidth: 300 }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {['businessPhotos', 'businessDocuments', 'proofOfBusiness'].map((field, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ padding: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {field.replace(/([A-Z])/g, ' $1').toUpperCase()}
                </Typography>
                <TextField
                  type="file"
                  onChange={(event) => handleFileChange(event, field)}
                  fullWidth
                  variant="outlined"
                  sx={{ mb: 1, maxWidth: 300 }}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSaveDetails}
        >
          Save Details
        </Button>
        <Button
                variant="contained"
                color="secondary"
                size="medium"
                fullWidth
                onClick={handleContinue}  // Navigate on click
                sx={{
                  fontWeight: 'bold',
                  padding: '10px 16px',
                  marginTop: 2,
                }}
              >
                Continue
              </Button>
      </Box>
    </Box>
  );
};

export default Busproofs;





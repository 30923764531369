import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import './loginpage.css';
import Navbarpage from '../components/Navbar';
import LinkedIn from "../images/linkdin.svg"; // Import LinkedIn image
import Google from "../images/google.svg";   // Import Google image
import AlertMessage from './AlertMessage'; 
import { useLocation } from 'react-router-dom';
import ExploreresNav from './ExploreresNav';
import { FaRegArrowAltCircleLeft } from "react-icons/fa";

import { IoMdHelpCircle } from "react-icons/io";


export const Explorelogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertMessage, setAlertMessage] = useState('');
  const location = useLocation();

  const { type } = location.state || {}; // Default to undefined if no state is passed

  const navigate = useNavigate();

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleLogin = async () => {
    if (!username || !password) {
      setAlertSeverity('warning');
      setAlertMessage('Please fill in all fields.');
      setAlertOpen(true);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post('https://api.pmitnetwork.com/loginn', {
        username,
        password,
      });
      if (response.data.success) {
        const role = response.data.role;
        setAlertSeverity('success');
        setAlertMessage(`Welcome, ${role}!`);
        setAlertOpen(true);
        localStorage.setItem('role', role);
        setTimeout(() => {
          Cookies.set('token', response.data.token);
        //   navigate(`/${role.toLowerCase()}`, { state: { username, role } });
        if (type === 'consumer') {
            navigate('/home', { state: { username, role} });
          } else if (type === 'business') {
            navigate('/businesshome', { state: { username, role} });
          } else {
            navigate(`/${role.toLowerCase()}`, { state: { username, role } });
          }
        }, 1000);
      } else {
        setAlertSeverity('error');
        setAlertMessage('Invalid credentials');
        setAlertOpen(true);
      }
    } catch (error) {
      setAlertSeverity('error');
      setAlertMessage('An error occurred during login. Please try again later.');
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

//   {type && <p>You came from the {type} section.</p>}


  return (
    <div className='lg-login-body'>
      <ExploreresNav  />

      <div className="lg-loginPage-container bg-light-subtle">
        <div className="lg-loginPage-card">
           {/* {type} */}
          <h2 className="lg-loginPage-title-left">Login</h2>
          <div className="lg-social-login-buttons">
            <button className="lg-google-login">
              <img src={Google} alt="Google" className="lg-social-icon" /> 
              <span className="lg-google-text">Login with Google</span>
            </button>
            <button className="lg-linkedin-login">
              <img src={LinkedIn} alt="LinkedIn" className="lg-social-icon" /> 
              <span className="lg-linkedin-text">Login with LinkedIn</span>
            </button>
          </div>

          <div className="lg-divider">or</div>

          <div className={`lg-loginPage-inputBox ${username && 'lg-active'}`}>
            <input
              type="text"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <span className="lg-loginPage-user">Email</span>
          </div>

          <div className={`lg-loginPage-inputBox ${password && 'lg-active'}`}>
            <input
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className="lg-loginPage-user">Password</span>
          </div>

          <button className="lg-loginPage-enter" onClick={handleLogin}>
            {loading ? 'Logging in...' : 'Login'}
          </button>

          <p className="lg-forgot-password"><Link to="/forgetpassword" className="forget-password-link">Forget Password <IoMdHelpCircle className="forget-password-icon" /></Link></p>
          <p className="lg-forgot-password"><Link to="/explore" className='forget-back'><FaRegArrowAltCircleLeft   className="forget-back-icon" />Back</Link></p>
          
   



          <p className="lg-by-signing-text">
            By signing in, you agree to our Terms of Use & Privacy Policy.
          </p>
        </div>

        <AlertMessage
          open={alertOpen}
          severity={alertSeverity}
          message={alertMessage}
          handleClose={handleAlertClose}
        />
      </div>
    </div>
  );
};

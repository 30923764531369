import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import ChatWindow from './ChatWindow';
import './ChatApp.css';
import axios from 'axios';

const ChatApp = () => {
  const [messages, setMessages] = useState({});
  const [selectedChat, setSelectedChat] = useState('General Chat');
  const [message, setMessage] = useState('');
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Handle screen resizing for mobile/tablet vs desktop
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsSidebarVisible(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch messages from the backend when selectedChat changes
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`https://api.pmitnetwork.com/messages/${selectedChat}`);
        setMessages((prevMessages) => ({
          ...prevMessages,
          [selectedChat]: response.data,
        }));
      } catch (err) {
        console.error('Error fetching messages:', err);
      }
    };

    fetchMessages();
  }, [selectedChat]);

  // Handle sending a message
  const handleSendMessage = async () => {
    if (message.trim()) {
      const newMessage = {
        user: 'You',  // Replace with dynamic user if needed
        text: message,
        time: new Date().toLocaleTimeString(),
      };

      setMessages((prevMessages) => ({
        ...prevMessages,
        [selectedChat]: [...prevMessages[selectedChat], newMessage],
      }));

      try {
        await axios.post('https://api.pmitnetwork.com/messages', {
          chat_room: selectedChat,
          user: 'You',  // Replace with dynamic user if needed
          message: newMessage.text,
        });
      } catch (err) {
        console.error('Error sending message:', err);
      }
      
      setMessage('');
    }
  };

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
    setIsSidebarVisible(false);
  };

  const handleBackClick = () => {
    setIsSidebarVisible(true);
  };

  return (
    <div className="chat-app">
      {!isMobile && (
        <>
          <Sidebar setSelectedChat={setSelectedChat} messages={messages} />
          <ChatWindow
            selectedChat={selectedChat}
            messages={messages[selectedChat] || []}
          />
        </>
      )}

      {isMobile && isSidebarVisible && (
        <Sidebar setSelectedChat={handleChatClick} messages={messages} />
      )}
      {isMobile && !isSidebarVisible && (
        <ChatWindow
          selectedChat={selectedChat}
          messages={messages[selectedChat] || []}
          onBackClick={handleBackClick}
        />
      )}

      {(!isMobile || !isSidebarVisible) && (
        <div className="message-input-container">
          <input
            type="text"
            placeholder="Type a message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      )}
    </div>
  );
};

export default ChatApp;

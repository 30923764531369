// Home.jsx
import React, {useState,useEffect} from 'react';
import BusinessSidebar from './BusinessSidebar';
import Businesscontent from './Businesscontent';
import './Businesshome.css'; // Optional: style for layout
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

 
const Businesshome = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [userDetails, setUserDetails] = useState(null);


  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "user") {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "user") {
        navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = Cookies.get("token");
        const response = await axios.get("https://api.pmitnetwork.com/user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };

    fetchUserData();
  }, [navigate]);
  console.log("username in business",username);

  useEffect(() => {
    // Function to fetch user details after login
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`https://api.pmitnetwork.com/api/getuserdetails/afterlogin/${username}`);
        const registeredId = response.data[0]?.registeredId; 
        
        setUserDetails(registeredId);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    if (username) {
      fetchUserDetails();
    }
  }, [username]);
  console.log("userdata in business", userDetails);

  return (
    <div className="home-container">
      {/* Sidebar on the left */}
      <BusinessSidebar />
     
     {/* Main content in the center */}
     <Businesscontent />
    </div>
  );
};
 
export default Businesshome;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Consumerbusiness.css';
import consumer from "../images/consumer.png";
import business from "../images/business.jpeg";
 
const ConsumersPage = () => {
  const [activeTab, setActiveTab] = useState('consumer');
  const navigate = useNavigate();
 
 
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
 

  const [selectedOption, setSelectedOption] = useState('');

  

  const handleButtonClick = (type, path) => {
    setSelectedOption(type); 
    setTimeout(() => {
      navigate(path, { state: { type } }); 
    }, 200); 
  };

 
 
  return (
    <div className="tab-container">
   
      
  <div className="tab-content mt-3">
  <div className="consumer-content">
  <div className='d-flex gap-2 justify-content-center align-items-center'>
  <input
    type="checkbox"
    id="homeCheckbox"
    checked={selectedOption === 'home'}
    // onChange={() => handleButtonClick('home', '/home')} 
    className="custom-checkbox mb-3"
  />
        
        <button
              className="custom-button consumer-btn"
              onClick={() => handleButtonClick('home', '/home')} 
            >
              Consumer <br />
              C2C and C2B
            </button>
        </div>
        <img
          src={consumer}
          alt="Consumer"
          className="tab-image"
         
        />
      </div>
   
      
          <div className="business-content">
          <div className='d-flex gap-2 justify-content-center align-items-center'>
  <input
    type="checkbox"
    id="businesshomeCheckbox"
    checked={selectedOption === 'businesshome'}
    // onChange={() => handleButtonClick('businesshome', '/businesshome')}
    className="custom-checkbox mb-3"
  />
            <button
              className="custom-button business-btn"
              onClick={() => handleButtonClick('businesshome', '/businesshome')} 
            >
              Business <br/> B2C and  B2B
            </button>
           </div>
            <img
              src={business}
              alt="Business"
              className="business-image"
             
            />
          </div>
      
      </div>
    </div>
  );
}
 
export default ConsumersPage;

import React from 'react'
import './Textpartsecond.css';

const Textpartsecond = () => {
  return (
    <div className='text-par-whole-main'>
        <div className='text-part1-class'>
            <p>Find the right connections at the <strong className='strongtext'>Right Time!</strong></p>
        </div>
        <div className='text-part2-class'>
            <p><strong className='strongtext strongtextcolor'>Put me in touch</strong> simplifies the process, ensuring you're always connected to the people who matters most two are bussiness </p>
        </div>
    </div>
  )
}

export default Textpartsecond
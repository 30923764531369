// import { useState } from "react";
// import axios from "axios";
// import { FaBell, FaImage, FaPoll, FaLink, FaAlignLeft, FaSearch, FaEye, FaEyeSlash } from "react-icons/fa";
// import './Navbar.css';
// function Signup() {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
//   const [signupPasswordVisible, setSignupPasswordVisible] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [dateOfBirth, setDateOfBirth] = useState('');
//   const toggleSignupPasswordVisibility = () => setSignupPasswordVisible(!signupPasswordVisible);
//   const toggleSignupModal = () => {
//     setIsSignupModalOpen(!isSignupModalOpen);
//     // setIsMobileNavbarVisible(isSignupModalOpen);
//     // Close login modal if open
//   };
//   const toggleModal = () => setIsModalOpen(!isModalOpen);
//   const handleSignup = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:5000/api/users', {
//         email,
//         password,
//         date_of_birth: dateOfBirth,
//       });
//       if (response.status === 201) {
//         setSuccessMessage('Signup successful!');
//         setErrorMessage('');
//         window.location.reload();
//       }
//     } catch (error) {
//       setErrorMessage('Error during signup. Please try again.');
//       setSuccessMessage('');
//     }
//   };
//   return (
//     <div>
//     {isSignupModalOpen && (
//       <div className="signup-modal-overlay" onClick={toggleSignupModal}>
//         <div className="signup-modal-content" onClick={(e) => e.stopPropagation()}>
//         {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
//         {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
//           <div className="signup-modal-header">
//             <h1 className='title1'>Sign Up</h1>
//             <div className="close-icon" onClick={toggleSignupModal}>
//               &#10005; {/* X icon for close */}
//             </div>
//           </div>
          
//           <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}   className="signup-modal-input" />
          
//           <div className="signup-password-field">
//             <input
//               type={signupPasswordVisible ? "text" : "password"}
//               placeholder="Password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               className="signup-modal-input"
//             />
//             <div className="password-icon" onClick={toggleSignupPasswordVisibility}>
//               {signupPasswordVisible ? <FaEyeSlash /> : <FaEye />}
//             </div>
//           </div>
          
//           <input type="date" placeholder="Date of Birth" value={dateOfBirth}   onChange={(e) => setDateOfBirth(e.target.value)} className="signup-modal-input" />
    
//           <button className="signup-button" onClick={handleSignup}>Continue</button>
//           <h6>OR</h6>
//           <button className="google-signup-button">Continue with Google</button>
    
//           <p className="terms">
//             By continuing, you agree to our <a href="#">Terms & Conditions</a>.
//           </p>
    
//           <p className="already-login">
//             Already have an account? <span >Login</span>
//           </p>
//         </div>
//       </div>
//     )}
//     </div>
//   )
// }

// export default Signup


import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import './Signup.css'; // Ensure this contains styles for the modal

function Signup() {
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(true); // Default to open for demonstration
  const [signupPasswordVisible, setSignupPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
const history=useNavigate();
  const toggleSignupPasswordVisibility = () => setSignupPasswordVisible(!signupPasswordVisible);
  const toggleSignupModal = () => setIsSignupModalOpen(!isSignupModalOpen);

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5001/api/users', {
        email,
        password,
        date_of_birth: dateOfBirth,
      });
      if (response.status === 201) {
        setSuccessMessage('Signup successful!');
        setErrorMessage('');
        // window.location.reload();
        history('/')
      }
    } catch (error) {
      setErrorMessage('Error during signup. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="whole-signup-content">
      {isSignupModalOpen && (
        <div className="signup-modal-overlay1" onClick={toggleSignupModal}>
          <div className="signup-modal-content1" onClick={(e) => e.stopPropagation()}>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
            <div className="signup-modal-header1">
              <h1 className='title12'>Sign Up</h1>
              {/* <div className="close-icon12" onClick={toggleSignupModal}>
              <Link to='/'>
                &#10005; 
                </Link>
              </div> */}
            </div>
            <form onSubmit={handleSignup}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="signup-modal-input1"
              />
              <div className="signup-password-field1">
                <input
                  type={signupPasswordVisible ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="signup-modal-input1"
                />
                <div className="password-icon12" onClick={toggleSignupPasswordVisibility}>
                  {signupPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
              <input
                type="date"
                placeholder="Date of Birth"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                className="signup-modal-input1"
              />
              <button type="submit" className="signup-button1">Continue</button>
              <h6>OR</h6>
              <button className="google-signup-button1">Continue with Google</button>
              <p className="terms1">
                By continuing, you agree to our <a href="#">Terms & Conditions</a>.
              </p>
              <p className="already-login1">
                Already have an account? <Link to='/login'><span onClick={() => {/* Add navigation to login */}}>Login</span></Link>
              </p>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Signup;

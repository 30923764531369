

// import React, {useState} from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import '../components/Consumerbusiness.css';
// import consumer from "../images/consumer.png";
// import business from "../images/business.jpeg";
// import { FaRegArrowAltCircleLeft } from "react-icons/fa";


// const ExplorePage = () => {
//   const navigate = useNavigate();

  

//   const [selectedOption, setSelectedOption] = useState(''); 
//   const handleButtonClick = (type) => {
//     // console.log(type);
//     // setSelectedOption(type)
//     // navigate('/res', { state: { type } });

//     if (selectedOption === type) {
//       setSelectedOption(''); 
//     } else {
//       setSelectedOption(type); 
//       setTimeout(() => {
//         navigate('/res', { state: { type } }); 
//       }, 200);
//     }
//   };

 

  

  

//   return (
//     <div className="tab-container">
//       <div className="tab-content">
//         <div className="consumer-content">
         
//         <div className='d-flex gap-2 justify-content-center align-items-center'>
//           <input
//             type="checkbox"
//             id="consumerCheckbox"
//             checked={selectedOption == 'consumer'}
//             onChange={() => handleButtonClick('consumer')}
//             className="custom-checkbox mb-3"
//           />
        
//           <button
//             className={`custom-button consumer-btn ${selectedOption === 'consumer' ? 'selected' : ''}`}
           
//           >
//           Consumer<br/>
//             C2C and C2B
//           </button>
//           </div>
          
//           <img
//             src={consumer}
//             alt="Consumer"
//             className="tab-image"
//             // onClick={() => handleButtonClick('consumer')}
//           />
//         </div>

//         <div className="business-content">
//         <div className='d-flex gap-2 justify-content-center align-items-center'>
         
//           <input
//             type="checkbox"
//             id="businessCheckbox"
//             checked={selectedOption === 'business'}
//             onChange={() => handleButtonClick('business')}
//             className="custom-checkbox mb-3"
//           />
        
          
//           <button
//             className={`custom-button business-btn ${selectedOption === 'business' ? 'selected' : ''}`}>
//             Business <br />
//             B2C and B2B
//           </button>
//           </div>
//           {/* <button
//             className="custom-button business-btn"
//             onClick={() => handleButtonClick('business')}
//           >
//             Business <br /> B2C and B2B
//           </button> */}
//           <img
//             src={business}
//             alt="Business"
//             className="business-image"
//             // onClick={() => handleButtonClick('business')}
//           />
//         </div>
//       </div>

//       <Link to="/" className="back-link">  <FaRegArrowAltCircleLeft  className="back-icon" /> 
//       Back</Link>
//     </div>
//   );
// };

// export default ExplorePage;

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../components/Consumerbusiness.css';
import consumer from "../images/consumer.png";
import business from "../images/business.jpeg";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";

const ExplorePage = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');

  const handleButtonClick = (type) => {
    // Set the selected option and navigate to the next page
    setSelectedOption(type);
    setTimeout(() => {
      navigate('/res', { state: { type } });
    }, 200);
  };

  const isSelected = (type) => selectedOption === type;

  return (
    <div className="tab-container">
      <div className="tab-content">
        <div className="consumer-content">
          <div className='d-flex gap-2 justify-content-center align-items-center'>
            {/* Button for Consumer */}
            <button
              className={`custom-button consumer-btn ${isSelected('consumer') ? 'selected' : ''}`}
              onClick={() => handleButtonClick('consumer')}
            >
              Consumer<br />
              C2C and C2B
            </button>
          </div>
          <img
            src={consumer}
            alt="Consumer"
            className="tab-image"
          />
        </div>

        <div className="business-content">
          <div className='d-flex gap-2 justify-content-center align-items-center'>
            {/* Button for Business */}
            <button
              className={`custom-button business-btn ${isSelected('business') ? 'selected' : ''}`}
              onClick={() => handleButtonClick('business')}
            >
              Business<br />
              B2C and B2B
            </button>
          </div>
          <img
            src={business}
            alt="Business"
            className="business-image"
          />
        </div>
      </div>

      {/* Back link with an icon */}
      {/* <Link to="/" className="back-link">
        <FaRegArrowAltCircleLeft className="back-icon" />
        Back
      </Link> */}
    </div>
  );
};

export default ExplorePage;

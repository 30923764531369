import React, { useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Card,
  CardMedia,
} from "@mui/material";
import { Description, Image } from "@mui/icons-material";
import SideBar from "./SideBar"; // Import the Sidebar component

const SharedMedia = () => {
  const [activeTab, setActiveTab] = useState(0);

  // Sample data for files and images
  const files = [
    { name: "Project_Plan.pdf", type: "PDF", size: "2 MB" },
    { name: "Budget_Sheet.xlsx", type: "Excel", size: "500 KB" },
    { name: "Meeting_Notes.docx", type: "Word", size: "1.2 MB" },
  ];

  const images = [
    { src: "https://via.placeholder.com/150", title: "Team Photo" },
    { src: "https://via.placeholder.com/150", title: "Product Demo" },
    { src: "https://via.placeholder.com/150", title: "Event Banner" },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#fff",
        minHeight: "100vh",
      }}
    >
      {/* Sidebar */}
      <Box sx={{ width: "300px", flexShrink: 0 }}>
        <SideBar />
      </Box>

      {/* Main Content */}
      <Box sx={{ flex: 1, padding: "20px" }}>
        {/* Header */}
        <Typography variant="h4" fontWeight="bold" mb={3}>
          Shared Media
        </Typography>

        {/* Tabs */}
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          textColor="primary"
          indicatorColor="primary"
          sx={{ marginBottom: "20px" }}
        >
          <Tab label="Shared Files" />
          <Tab label="Shared Images" />
        </Tabs>

        {/* Tab Content */}
        {activeTab === 0 && (
          <Box>
            {/* Shared Files List */}
            <List>
              {files.map((file, index) => (
                <ListItem
                  key={index}
                  sx={{
                    backgroundColor: "#fff",
                    mb: 2,
                    borderRadius: "8px",
                  }}
                >
                  <ListItemIcon>
                    <Description color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    secondary={`${file.type} • ${file.size}`}
                    primaryTypographyProps={{ fontWeight: "bold" }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {activeTab === 1 && (
          <Box>
            {/* Shared Images Grid */}
            <Grid container spacing={3}>
              {images.map((image, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ borderRadius: "10px", overflow: "hidden" }}>
                    <CardMedia
                      component="img"
                      image={image.src}
                      alt={image.title}
                      height="150"
                    />
                    <Typography
                      sx={{
                        padding: "10px",
                        fontWeight: "bold",
                        textAlign: "center",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      {image.title}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SharedMedia;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { BiLogOut } from "react-icons/bi";
import ConsumersPage from "../components/Consumerbusiness";


export const User = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "user") {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "user") {
        navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = Cookies.get("token");
        const response = await axios.get("https://api.pmitnetwork.com/user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleLogout = () => {
    Cookies.remove("token");
    navigate("/login");
  };

  return (
    // <div>
    //   <ConsumersPage/>
    //   <button onClick={handleLogout}>Logout</button>
    //  {/* <p> username:{username}</p>
    // <p>role:{role}</p>  */}
    // </div>
    // <div className="user-page-container">
    //   <div className="logout-container">
    //     <button className="logout-button" onClick={handleLogout}>
    //       Logout
    //     </button>
    //   </div>
    //   <ConsumersPage />
    // </div>
    <div className="user-page-container">
    <div className="logout-container">
      <div className="logout-icon" onClick={handleLogout}>
        <BiLogOut size={30} />
        <span className="logout-tooltip">Logout</span>
      </div>
    </div>
    <ConsumersPage />
  </div>
  );
};




// import React, { useState, useEffect } from "react";
// import "./maincontent.css";
// import { FaPaperPlane, FaFilter } from "react-icons/fa";
// import userpicture from "../images/userpicture.png";
// import commentIcon from "../images/Impress-one.svg";
// import likeIcon from "../images/Impress.svg";
// import shareIcon from "../images/Impress-two.svg";
// import ImageIcon from "../images/imageicon.svg";
// import axios from "axios";
// import { useNavigate, Link } from "react-router-dom";
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
// import Gif from "../images/Gif.svg";

// import Pdf from "../images/attach.webp";
 
// const Maincontent = () => {
//   const [filterVisible, setFilterVisible] = useState(false);
//   const [questionText, setQuestionText] = useState("");
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [selectedGif, setSelectedGif] = useState(null);
//   const [selectedPdf, setSelectedPdf] = useState(null);
//   const [userDetails, setUserDetails] = useState(null);
//   const [posts, setPosts] = useState([]);
//   const [commentText, setCommentText] = useState("");
//   const [expandedCommentId, setExpandedCommentId] = useState(null);
//   const [showCommentsPopup, setShowCommentsPopup] = useState(false);
//   const [commentsData, setCommentsData] = useState([]);
//   const [postedpostData, setPostedPostData] = useState([]);
 
//   const [selectedPostId, setSelectedPostId] = useState(null);
 
//   const handleImageChange = (e) => setSelectedImage(e.target.files[0]);
//   const handleGifChange = (e) => setSelectedGif(e.target.files[0]);
//   const handlePdfChange = (e) => setSelectedPdf(e.target.files[0]);
 
//   const navigate = useNavigate();
//   const [username, setUsername] = useState("");
//   const [role, setRole] = useState("");
 
//   useEffect(() => {
//     const role = localStorage.getItem("role");
//     if (role !== "user") {
//       navigate("/");
//     }
//   }, [navigate]);
 
//   useEffect(() => {
//     const token = Cookies.get("token");
//     if (!token) {
//       navigate("/");
//     } else {
//       const decoded = jwtDecode(token);
//       setUsername(decoded.username);
//       setRole(decoded.role);
//       if (decoded.role !== "user") {
//         navigate("/");
//       }
//     }
//   }, [navigate]);
 
//   useEffect(() => {
//     // Function to fetch user details after login
//     const fetchUserDetails = async () => {
//       try {
//         const response = await axios.get(
//           `https://api.pmitnetwork.com/api/getuserdetails/afterlogin/${username}`
//         );
//         const registeredId = response.data[0]?.registeredId;
 
//         setUserDetails(registeredId);
//       } catch (error) {
//         console.error("Error fetching user details:", error);
//       }
//     };
 
//     if (username) {
//       fetchUserDetails();
//     }
//   }, [username]);
//   console.log("userdata", userDetails);
 
//   const handleSubmit = async () => {
//     if (!questionText.trim()) {
//       alert("Please ask  your Question before posting.");
//       return; // Exit the function if question is empty
//     }
//     const formData = new FormData();
//     formData.append("question", questionText);
//     formData.append("loggeduserid", userDetails);
//     if (selectedImage) formData.append("image", selectedImage);
//     if (selectedGif) formData.append("gif", selectedGif);
//     if (selectedPdf) formData.append("pdf", selectedPdf);
 
//     try {
//       const response = await axios.post(
//         "https://api.pmitnetwork.com/api/upload",
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       console.log("Response:", response.data);
//       setQuestionText("");
//       setSelectedImage(null);
//       setSelectedGif(null);
//       setSelectedPdf(null);
//       fetchPosts();
//     } catch (error) {
//       console.error("Error uploading data:", error);
//     }
//   };
 
//   const toggleFilter = () => {
//     setFilterVisible(!filterVisible);
//   };
 
//   const fetchPosts = async () => {
//     try {
//       const response = await axios.get("https://api.pmitnetwork.com/api/getPosts");
//       setPosts(response.data);
//     } catch (error) {
//       console.error("Error fetching posts:", error);
//     }
//   };
 
//   useEffect(() => {
//     fetchPosts();
//   }, []);
//   console.log("postdata", posts);
 
//   const handleCommentSubmit = async (postId) => {
//     // Function to handle comment submission
//     try {
//       await axios.post(`https://api.pmitnetwork.com/api/addComment`, {
//         postId,
//         comment: commentText,
//         commenteduserId: userDetails,
//       });
//       setCommentText("");
//       setExpandedCommentId(null); // Close comment section after submission
//       fetchPosts();
//     } catch (error) {
//       console.error("Error submitting comment:", error);
//     }
//   };
 
//   const fetchComments = async (postId) => {
//     try {
//       const response = await axios.get(
//         `https://api.pmitnetwork.com/api/getComments/${postId}`
//       );
//       if (response.data.length === 0) {
//         setCommentsData([{ commentDescription: "No comments" }]);
//       } else {
//         setCommentsData(response.data);
//       }
//       // setCommentsData(response.data);
 
//       const postDetailsResponse = await axios.get(
//         `https://api.pmitnetwork.com/api/getpostedpostDetails/${postId}`
//       );
//       setPostedPostData(postDetailsResponse.data);
 
//       setShowCommentsPopup(true);
//     } catch (error) {
//       console.error("Error fetching comments:", error);
//     }
//   };
//   console.log("comments dtaa", commentsData);
 
//   // Function to handle click on comments icon
//   const handleCommentsIconClick = (postId) => {
//     setSelectedPostId(postId); // Set the selected post ID
 
//     fetchComments(postId);
//   };
 
//   return (
//     <div className="matter-container">
//       <div className="main-content">
       
 
//         <div className="share-thoughts">
//           <input
//             type="text"
//             placeholder="Post Your Questions..."
//             value={questionText}
//             onChange={(e) => setQuestionText(e.target.value)}
//           />
//           <div className="media-icons-send">
//             <div className="media-icons">
//               <label>
//                 <img src={ImageIcon} alt="Image Icon" className="media-icon" />
//                 <input
//                   type="file"
//                   accept="image/*"
//                   onChange={handleImageChange}
//                   style={{ display: "none" }}
//                 />
//               </label>
//               <label>
//                 <img src={Gif} alt="GIF Icon" className="media-icon" />
//                 <input
//                   type="file"
//                   accept="image/gif, video/mp4, video/webm, video/ogg" 
//                   onChange={handleGifChange}
//                   style={{ display: "none" }}
//                 />
//               </label>
//               <label>
//                 <img src={Pdf} alt="PDF Icon" className="media-icon" />
//                 <input
//                   type="file"
//                   accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .odt, .csv, .zip"
//                   onChange={handlePdfChange}
//                   style={{ display: "none" }}
//                 />
//               </label>
//             </div>
//             <button className="send-btn" onClick={handleSubmit}>
//               <FaPaperPlane />
//             </button>
//           </div>
//         </div>
 
//         <div className="posts-container">
//           {posts.map((post) => (
//             <div key={post.id} className="post">
//               <div className="post-header">
//                 <img src={userpicture} alt="User Avatar" className="avatar" />
//                 <p className="user">{post.username}</p>
//               </div>
//               <p>{post.postques}</p>
 
//               {post.images && (
//                 <img
//                   src={`https://api.pmitnetwork.com/uploads/${post.images}`}
//                   alt="Post"
//                   className="post-image"
//                 />
//               )}
//               <div className="post-footer">
//                 <p className="timestamp">{post.timestampdata}</p>
//                 <div className="post-stats">
//                   <span className="icon-text">
//                     <img src={likeIcon} alt="Likes" className="icon-image" />
//                     {post.likes} Likes
//                   </span>
//                   <span
//                     className="icon-text"
//                     onClick={() => handleCommentsIconClick(post.postId)}
//                   >
//                     <img
//                       src={commentIcon}
//                       alt="Comments"
//                       className="icon-image"
//                     />
//                     {post.comments} Comments
//                   </span>
//                   <span className="icon-text">
//                     <img src={shareIcon} alt="Shares" className="icon-image" />
//                     {post.shares} Spread
//                   </span>
//                 </div>
//               </div>
//               <div className="post-actions">
//                 <button className="action-btn">
//                   <img src={likeIcon} alt="Like" className="icon-image" />
//                 </button>
//                 {/* <button className="action-btn">
//                   <img src={commentIcon} alt="Comment" className="icon-image" />
//                 </button> */}
//                 <button
//                   className="action-btn"
//                   // onClick={() => setExpandedCommentId(post.postId)}
 
//                   onClick={() => {
//                     if (expandedCommentId === post.postId) {
//                       setExpandedCommentId(null);
//                     } else {
//                       setExpandedCommentId(post.postId);
//                     }
//                   }}
//                 >
//                   <img src={commentIcon} alt="Comment" className="icon-image" />
//                 </button>
//                 <button className="action-btn">
//                   <img src={shareIcon} alt="Share" className="icon-image" />
//                 </button>
//               </div>
 
//               {expandedCommentId === post.postId && (
//                 <div className="comment-section">
//                   {/* <input
//                     type="text"
//                     placeholder="Post your comment here..."
//                     value={commentText}
//                     onChange={(e) => setCommentText(e.target.value)}
//                   />
//                   <button onClick={() => handleCommentSubmit(post.postId)}>
//                     Send
//                   </button> */}
//                   <input
//           type="text"
//           placeholder="Post your comment here..."
//           value={commentText}
//           onChange={(e) => setCommentText(e.target.value)}
//         />
//         <FaPaperPlane
//           className="send-icon"
//           onClick={() => handleCommentSubmit(post.postId)}
//         />
//                 </div>
                
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
 
//       {showCommentsPopup && (
//         <div className="comments-popup">
//           <div className="popup-content">
//             <button onClick={() => setShowCommentsPopup(false)}>Close</button>
//             {/* <h3>Comments for Post ID: {selectedPostId}</h3> */}
//             <div className="d-flex flex-row" style={{ gap: "50px" }}>
//               <div>
//                 <ul>
//                   {commentsData.map((comment) => (
//                     <div key={comment.commentId}>
//                       <div className="d-flex">
//                       <img src={userpicture} style={{width:'30px', height:'30px', borderRadius:'50px'}}></img>
//                        {comment.commentedusername}........</div>
//                       <div>
//                       {comment.commentDescription}
//                       </div>
//                     </div>
//                   ))}
//                 </ul>
//               </div>
//               <div>
//                 {postedpostData.map((data) => (
//                   <div>
//                     {data.postques}
//                     {data.images && (
//                       <img
//                         src={`https://api.pmitnetwork.com/uploads/${data.images}`}
//                         alt="Post"
//                         className="post-image"
//                       />
//                     )}
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
 
//       <div className="right-sidebar">
//       <Link to="/businesshome">
//           <button
//              className="switch-business m-2"
//              title="Switch  to your business Account"
//            >
//              Switch to Business
//            </button>{" "}
//       </Link>

 
//         <input type="text" placeholder="Search..." className="search-input" />
//         <div className="card">
//           <h3 className="sortby-heading">Sort by</h3>
//           <ul>
//             <li className="card-list">
//               Default post <span className="count">25</span>
//             </li>
//             <li className="card-list">
//               Trending post <span className="count">10</span>
//             </li>
//             <li className="card-list">
//               Friends post <span className="count">5</span>
//             </li>
//             <li className="card-list">
//               Investment opportunities <span className="count">8</span>
//             </li>
//             <li className="card-list">
//               Business acquisitions <span className="count">3</span>
//             </li>
//             <li className="card-list">
//               Sell my business <span className="count">12</span>
//             </li>
//           </ul>
//         </div>
//         <div className="card">
//           <h3 className="popular-heading">Popular hashtags</h3>
//           <ul>
//             <li className="card-list">#AppleStock</li>
//             <li className="card-list">#Bitcoin</li>
//             <li className="card-list">#TeslaLaunch</li>
//             <li className="card-list">#AmazonMarkets</li>
//             <li className="card-list">#FacebookFeature</li>
//             <li className="card-list">#GoogleAI</li>
//             <li className="card-list">#MicrosoftAcquires</li>
//             <li className="card-list">#NetflixLaunches</li>
//             <li className="card-list">#UberServices</li>
//             <li className="card-list">#AppleIntroduces</li>
//           </ul>
//         </div>
//       </div>
 
//       <button className="floating-filter-btn" onClick={toggleFilter}>
//         <FaFilter />
//       </button>
 
//       <div className={`filter-overlay ${filterVisible ? "active" : ""}`}>
//         <div className="filter-content">
//           <h3>Filter & Search</h3>
//           <input type="text" placeholder="Search..." className="search-input" />
//           <div className="card">
//             <h3>Sort by</h3>
//             <ul>
//               <li>
//                 Default post <span className="count">25</span>
//               </li>
//               <li>
//                 Trending post <span className="count">10</span>
//               </li>
//               <li>
//                 Friends post <span className="count">5</span>
//               </li>
//               <li>
//                 Investment opportunities <span className="count">8</span>
//               </li>
//               <li>
//                 Business acquisitions <span className="count">3</span>
//               </li>
//               <li>
//                 Sell my business <span className="count">12</span>
//               </li>
//             </ul>
//           </div>
//           <div className="card">
//             <h3>Popular hashtags</h3>
//             <ul>
//               <li>#AppleStock</li>
//               <li>#Bitcoin</li>
//               <li>#TeslaLaunch</li>
//               <li>#AmazonMarkets</li>
//               <li>#FacebookFeature</li>
//               <li>#GoogleAI</li>
//             </ul>
//           </div>
//           <button className="close-btn" onClick={toggleFilter}>
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };
 
// export default Maincontent;












import React, { useState, useEffect } from "react";
import "./maincontent.css";
import { FaPaperPlane, FaFilter } from "react-icons/fa";
import userpicture from "../images/userpicture.png";
import commentIcon from "../images/Impress-one.svg";
import likeIcon from "../images/Impress.svg";
import shareIcon from "../images/Impress-two.svg";
import ImageIcon from "../images/imageicon.svg";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import Gif from "../images/Gif.svg";
import { FaTimes } from 'react-icons/fa';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import Pdf from "../images/attach.webp";
 

const Maincontent = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [questionText, setQuestionText] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedGif, setSelectedGif] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [posts, setPosts] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [expandedCommentId, setExpandedCommentId] = useState(null);
  const [showCommentsPopup, setShowCommentsPopup] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [postedpostData, setPostedPostData] = useState([]);
 
  const [selectedPostId, setSelectedPostId] = useState(null);
 
  const handleImageChange = (e) => setSelectedImage(e.target.files[0]);
  const handleGifChange = (e) => setSelectedGif(e.target.files[0]);
  const handlePdfChange = (e) => setSelectedPdf(e.target.files[0]);
 
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
 
  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "user") {
      navigate("/");
    }
  }, [navigate]);
 
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "user") {
        navigate("/");
      }
    }
  }, [navigate]);
 
  useEffect(() => {
    // Function to fetch user details after login
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `https://api.pmitnetwork.com/api/getuserdetails/afterlogin/${username}`
        );
        const registeredId = response.data[0]?.registeredId;
 
        setUserDetails(registeredId);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
 
    if (username) {
      fetchUserDetails();
    }
  }, [username]);
  console.log("userdata", userDetails);
 
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("question", questionText);
    formData.append("loggeduserid", userDetails);
    if (selectedImage) formData.append("image", selectedImage);
    if (selectedGif) formData.append("gif", selectedGif);
    if (selectedPdf) formData.append("pdf", selectedPdf);
 
    try {
      const response = await axios.post(
        "https://api.pmitnetwork.com/api/upload",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("Response:", response.data);
      setQuestionText("");
      setSelectedImage(null);
      setSelectedGif(null);
      setSelectedPdf(null);
      fetchPosts();
      setPopupVisible(true);
      setTimeout(() => setPopupVisible(false), 3000);
 
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };
 
  const toggleFilter = () => {
    setFilterVisible(!filterVisible);
  };
 
  const fetchPosts = async () => {
    try {
      const response = await axios.get("https://api.pmitnetwork.com/api/getPosts");
      setPosts(response.data);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };
 
  useEffect(() => {
    fetchPosts();
  }, []);
  console.log("postdata", posts);
 
  const handleCommentSubmit = async (postId) => {
    // Function to handle comment submission
    try {
      await axios.post(`https://api.pmitnetwork.com/api/addComment`, {
        postId,
        comment: commentText,
        commenteduserId: userDetails,
      });
      setCommentText("");
      setExpandedCommentId(null); // Close comment section after submission
      fetchPosts();
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };
 
  const fetchComments = async (postId) => {
    try {
      const response = await axios.get(
        `https://api.pmitnetwork.com/api/getComments/${postId}`
      );
      if (response.data.length === 0) {
        setCommentsData([{ commentDescription: "No comments" }]);
      } else {
        setCommentsData(response.data);
      }
      // setCommentsData(response.data);
 
      const postDetailsResponse = await axios.get(
        `https://api.pmitnetwork.com/api/getpostedpostDetails/${postId}`
      );
      setPostedPostData(postDetailsResponse.data);
 
      setShowCommentsPopup(true);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };
  console.log("comments dtaa", commentsData);
 
  // Function to handle click on comments icon
  const handleCommentsIconClick = (postId) => {
    setSelectedPostId(postId); // Set the selected post ID
 
    fetchComments(postId);
  };

 
 
  return (
    <div className="matter-container">
      <div className="main-content">
       
 
        <div className="share-thoughts">
          <input
            type="text"
            placeholder="Post Your Questions..."
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
          />
          <div className="media-icons-send">
            <div className="media-icons">
              <label>
                <img src={ImageIcon} alt="Image Icon" className="media-icon" />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </label>
              <label>
                <img src={Gif} alt="GIF Icon" className="media-icon" />
                <input
                  type="file"
                  accept="image/gif"
                  onChange={handleGifChange}
                  style={{ display: "none" }}
                />
              </label>
              <label>
                <img src={Pdf} alt="PDF Icon" className="media-icon" />
                <input
                  type="file"
                  accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .odt, .csv, .zip"
                  onChange={handlePdfChange}
                  style={{ display: "none" }}
                />
              </label>
            </div>
            <button className="send-btn" onClick={handleSubmit}>
              <FaPaperPlane />
            </button>
            {popupVisible && <div className="popup-fill">Post has been created. Waiting for the Approval!</div>}
 
 
          </div>
        </div>
 
        <div className="posts-container">
          {posts.map((post) => (
            <div key={post.id} className="post">
              <div className="post-header">
                <img src={userpicture} alt="User Avatar" className="avatar" />
                <p className="user">{post.username}</p>
              </div>
              <p>{post.postques}</p>
 
              {post.images && (
                <img
                  src={`https://api.pmitnetwork.com/uploads/${post.images}`}
                  alt="Post"
                  className="post-image"
                />
              )}
              <div className="post-footer">
                <p className="timestamp">{post.timestampdata}</p>
                <div className="post-stats">
                  <span className="icon-text">
                    <img src={likeIcon} alt="Likes" className="icon-image" />
                    {post.likes} Likes
                  </span>
                  <span
                    className="icon-text"
                    onClick={() => handleCommentsIconClick(post.postId)}
                  >
                    <img
                      src={commentIcon}
                      alt="Comments"
                      className="icon-image"
                    />
                    {post.comments} Comments
                  </span>
                  <span className="icon-text">
                    <img src={shareIcon} alt="Shares" className="icon-image" />
                    {post.shares} Spread
                  </span>
                </div>
              </div>
              <div className="post-actions">
                <button className="action-btn">
                  <img src={likeIcon} alt="Like" className="icon-image" />
                </button>
                {/* <button className="action-btn">
                  <img src={commentIcon} alt="Comment" className="icon-image" />
                </button> */}
                <button
                  className="action-btn"
                  // onClick={() => setExpandedCommentId(post.postId)}
 
                  onClick={() => {
                    if (expandedCommentId === post.postId) {
                      setExpandedCommentId(null);
                    } else {
                      setExpandedCommentId(post.postId);
                    }
                  }}
                >
                  <img src={commentIcon} alt="Comment" className="icon-image" />
                </button>
                <button className="action-btn">
                  <img src={shareIcon} alt="Share" className="icon-image" />
                </button>
              </div>
 
              {expandedCommentId === post.postId && (
                <div className="comment-section">
                  {/* <input
                    type="text"
                    placeholder="Post your comment here..."
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                  />
                  <button onClick={() => handleCommentSubmit(post.postId)}>
                    Send
                  </button> */}
                  <input
          type="text"
          placeholder="Post your comment here..."
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
        />
        <FaPaperPlane
          className="send-icon"
          onClick={() => handleCommentSubmit(post.postId)}
        />
                </div>
                
              )}
            </div>
          ))}
        </div>
      </div>
 
      {showCommentsPopup && (
        <div className="comments-popup">
          <div className="popup-content">
            {/* <button onClick={() => setShowCommentsPopup(false)}>Close</button> */}
            {/* <h3>Comments for Post ID: {selectedPostId}</h3> */}
           
      <div className="d-flex flex-row popup-comment-side">
      <div className="userimg">
      <h5>Post</h5> 
          {postedpostData.map((data) => (
            <div key={data.postId}>
              <p>{data.postques}</p>
              {data.images && (
                <img
                  src={`https://api.pmitnetwork.com/uploads/${data.images}`}
                  alt="Post"
                  className="post-image userpost-image"
                  
                />
              )}
            </div>
          ))}
        </div>
         {/* Right side: Comments section */}
         <div>
         <div className="popup-header d-flex  align-items-center">
    <h3 className="m-0 me-3 fw-bold comments-head">Comments</h3> {/* "Comments" heading */}
    <FaTimes onClick={() => setShowCommentsPopup(false)} style={{ cursor: 'pointer' }} />
  </div>
        
         <div className="popup-comments-section mt-2" >
          <ul className='popup-comments-scroll'>
            {commentsData.map((comment) => (
              <div key={comment.commentId} className="comments-item mb-3">
                <div className="d-flex align-items-center">
                  <img
                    src={userpicture}
                    alt="User"
                    style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                    
                  />
                  
                  <span className="ms-2 fw-bold comment-username">{comment.commentedusername}</span>
                  
                </div>
                <div className="comment-description ms-4 ">
                  {comment.commentDescription}
  <div className="d-flex justify-content-center mt-2">
    <div className="rounded-circle bg-primary p-2 me-3 d-flex justify-content-center align-items-center" style={{ cursor: "pointer" }}>
      <FaThumbsUp color="white" size={16} />
    </div>
    <div className="rounded-circle bg-secondary  p-2 d-flex justify-content-center align-items-center" style={{ cursor: "pointer" }}>
      <FaThumbsDown color="white"  size={16} />
    </div>
  </div>
  
                </div>
                
                 
              </div>
              
            ))}
          </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
)}
            {/* <div className="d-flex flex-row" style={{ gap: "50px" }}>
              <div>
                <ul>
                  {commentsData.map((comment) => (
                    <div key={comment.commentId}>
                      <div className="d-flex">
                      <img src={userpicture} style={{width:'30px', height:'30px', borderRadius:'50px'}}></img>
                       {comment.commentedusername}........</div>
                      <div>
                      {comment.commentDescription}
                      </div>
                    </div>
                  ))}
                </ul>
              </div>
              <div>
                {postedpostData.map((data) => (
                  <div>
                    {data.postques}
                    {data.images && (
                      <img
                        src={`https://api.pmitnetwork.com/uploads/${data.images}`}
                        alt="Post"
                        className="post-image"
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )} */}
 
      <div className="right-sidebar">
      <Link to="/businesshome">
          <button
             className="switch-business m-2"
             title="Switch  to your business Account"
           >
             Switch to Business
           </button>{" "}
      </Link>

 
        <input type="text" placeholder="Search..." className="search-input1" />
        <div className="card">
          <h3 className="sortby-heading">Sort by</h3>
          <ul>
            <li className="card-list">
              Default post <span className="count">25</span>
            </li>
            <li className="card-list">
              Trending post <span className="count">10</span>
            </li>
            <li className="card-list">
              Friends post <span className="count">5</span>
            </li>
            <li className="card-list">
              Investment opportunities <span className="count">8</span>
            </li>
            <li className="card-list">
              Business acquisitions <span className="count">3</span>
            </li>
            <li className="card-list">
              Sell my business <span className="count">12</span>
            </li>
          </ul>
        </div>
        <div className="card">
          <h3 className="popular-heading">Popular hashtags</h3>
          <ul>
            <li className="card-list">#AppleStock</li>
            <li className="card-list">#Bitcoin</li>
            <li className="card-list">#TeslaLaunch</li>
            <li className="card-list">#AmazonMarkets</li>
            <li className="card-list">#FacebookFeature</li>
            <li className="card-list">#GoogleAI</li>
            <li className="card-list">#MicrosoftAcquires</li>
            <li className="card-list">#NetflixLaunches</li>
            <li className="card-list">#UberServices</li>
            <li className="card-list">#AppleIntroduces</li>
          </ul>
        </div>
      </div>
 
      <button className="floating-filter-btn" onClick={toggleFilter}>
        <FaFilter />
      </button>
 
      <div className={`filter-overlay ${filterVisible ? "active" : ""}`}>
        <div className="filter-content">
          <h3>Filter & Search</h3>
          <input type="text" placeholder="Search..." className="search-input3" />
          <div className="card">
            <h3>Sort by</h3>
            <ul>
              <li>
                Default post <span className="count">25</span>
              </li>
              <li>
                Trending post <span className="count">10</span>
              </li>
              <li>
                Friends post <span className="count">5</span>
              </li>
              <li>
                Investment opportunities <span className="count">8</span>
              </li>
              <li>
                Business acquisitions <span className="count">3</span>
              </li>
              <li>
                Sell my business <span className="count">12</span>
              </li>
            </ul>
          </div>
          <div className="card">
            <h3>Popular hashtags</h3>
            <ul>
              <li>#AppleStock</li>
              <li>#Bitcoin</li>
              <li>#TeslaLaunch</li>
              <li>#AmazonMarkets</li>
              <li>#FacebookFeature</li>
              <li>#GoogleAI</li>
            </ul>
          </div>
          <button className="close-btn" onClick={toggleFilter}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
 
export default Maincontent;
// import React from 'react';
// import './ChatWindow';

// const MessageInput = ({ message, setMessage, handleSendMessage }) => {
//     return (
//         <div className="message-input-container">
//             <input
//                 type="text"
//                 value={message}
//                 onChange={(e) => setMessage(e.target.value)}
//                 placeholder="Type a message..."
//             />
//             <button className='chat_button' onClick={handleSendMessage}>Send</button>
//         </div>
//     );
// };

// export default MessageInput;
import React, { useState } from 'react';
import './ChatWindow.css';

const MessageInput = ({ message, setMessage, handleSendMessage }) => {

    const sendMessage = async () => {
        if (!message.trim()) return; // If the message is empty, don't send it

        // Prepare message data to send to the backend
        const messageData = {
            chat_room: "general",  // Example: replace with the actual chat room you're in
            user: "Raj",           // Example: replace with the actual user (you can pass user from parent component)
            message: message.trim() // Message text
        };

        try {
            // Send the message to the backend using fetch
            const response = await fetch("https://api.pmitnetwork.com/messages", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(messageData)
            });

            if (response.ok) {
                console.log("Message sent successfully!");
                setMessage(''); // Clear input field after sending the message
            } else {
                console.error("Error sending message");
            }
        } catch (error) {
            console.error("Error while sending message:", error);
        }
    };

    return (
        <div className="message-input-container">
            <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)} // Update message state
                placeholder="Type a message..."
            />
            <button className='chat_button' onClick={sendMessage}>Send</button> {/* Send button triggers sendMessage */}
        </div>
    );
};

export default MessageInput;

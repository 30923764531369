import React from "react";
import { TiMessages } from "react-icons/ti";
import './Footerpart.css'
import { FaLinkedin } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import Footerlogo from '../images/Footerlogo.svg';
import img1 from '../images/x.svg';
import img2 from '../images/Instagram.svg';
import img3 from '../images/Facebook.svg';
import img4 from  '../images/Linkedin.svg';






const Footerpart = () => {
  return (
    <div className="footerpartmain-main-parts-dt">
        <h2 className="f-heading-provide">Supercharge with your business Network with us!</h2>
      <div className="whole-content-footerpart">
        <div className="icons-div-whole">
          <div className="ft-logo-container">
            <div>
              {/* <TiMessages className="logo-dimensions-ft" /> */}
              <img src={Footerlogo} alt="logo"/>
            </div>
            <div className="text-cont-ft">
              <p className="head-ft-1">Put me</p>
              <p className="head-ft-2">in touch</p>
            </div>
          </div>
          <div className="complete-ft-icons">
          {/* <FaLinkedin />
          <FaFacebook />
          <AiFillInstagram />
          <FaXTwitter /> */}
          <img src={img1} alt="x"/>
          <img src={img2} alt="instagram"/>

          <img src={img4} alt="linkedin"/>
          <img src={img3} alt="facebook"/>



 
          </div>
        </div>
        <div className="icons-div-whole-text">
            <ul>
                <li>
                About
                </li>
                <li>Contact</li>
                <li>Colloborate</li>
                <li>Terms of uses</li>


            </ul>
        </div>
      </div>
    </div>
  );
};

export default Footerpart;

// import React from 'react'
// import './Mainpage.css';
// import Navbarpage from './Navbar';
// import Homepart from './Homepart';
// import Secondpartmain from './Secondpartmain';
// import Textpartsecond from './Textpartsecond';
// import Footerpart from './Footerpart';
// import Secondfooter from './Secondfooter';
// import ExplorePage from '../logincomponent/Explorepage';

// const Mainpage = () => {
//   return (
//     <div className='bg-light-subtle Mainpage-whole-classd '>
//         <div className='Mainpage-Navbar-part'>
//             <Navbarpage/>
//         </div>
//         <div className='Homepart-in-Mainpage'>
//         <Homepart/>
//         </div>
//         <div className='homepage-bhr-tag'>
//         <hr className='line-tag-ps'/>
//         </div>
//         <div class='homepage-textpartsecond'>
//           <Textpartsecond/>
//         </div>
//         <div class="homepage-secondpartmain">
//           <Secondpartmain/>
//         </div>
//         <div class="homepage-footer">
//           <Footerpart/>
//         </div>
//         <div className='lastpart-footer'>
//           <Secondfooter/>
//         </div>

        

//     </div>
//   )
// }

// export default Mainpage






import React, { useRef } from 'react';
import './Mainpage.css';
import Navbarpage from './Navbar';
import Homepart from './Homepart';
import Secondpartmain from './Secondpartmain';
import Textpartsecond from './Textpartsecond';
import Footerpart from './Footerpart';
import Secondfooter from './Secondfooter';
import Explorepage from '../logincomponent/Explorepage';
 
const Mainpage = () => {
  // Create a reference for the Consumerbusiness section
  const ExplorepageRef = useRef(null);
 
  // Function to scroll to the Consumerbusiness section
  const scrollToExplorepage = () => {
    ExplorepageRef.current.scrollIntoView({ behavior: 'smooth' });
  };
 
  return (
    <div className='bg-light-subtle Mainpage-whole-classd'>
      <div className='Mainpage-Navbar-part'>
        <Navbarpage />
      </div>
      <div className='Homepart-in-Mainpage'>
        {/* Pass the scroll function as a prop to Homepart */}
        <Homepart onExploreClick={scrollToExplorepage} />
      </div>
      <div className='homepage-bhr-tag'>
        <hr className='line-tag-ps' />
      </div>
      <div className='homepage-textpartsecond'>
        <Textpartsecond />
      </div>
      <div className='homepage-secondpartmain'>
        <Secondpartmain />
      </div>
      {/* Attach the ref to the Consumerbusiness section */}
      <div ref={ExplorepageRef} className='homepage-secondpart'>
        <Explorepage />
      </div>
      <div className='homepage-footer'>
        <Footerpart />
      </div>
      <div className='lastpart-footer'>
        <Secondfooter />
      </div>
    </div>
  );
};
 
export default Mainpage;
 
 
 
// Home.jsx
import React , { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Maincontent from './Maincontent';
import './home.css'; // Optional: style for layout
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
 
const Home = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "user") {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "user") {
        navigate("/");
      }
    }
  }, [navigate]);

  return (
    <div className="home-container">
      {/* Sidebar on the left */}
      
      <Sidebar />
     
      {/* Main content in the center */}
      <Maincontent />
    </div>
  );
};
 
export default Home;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './Sidebar.css';
import monkeyImage from '../images/monkey.png';
import axios from 'axios';

const Sidebar = ({ setSelectedChat, messages }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [chatRooms, setChatRooms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchChatRooms = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get("https://api.pmitnetwork.com/chatRooms");
                setChatRooms(response.data);
            } catch (err) {
                setError('Failed to fetch chat rooms');
            } finally {
                setIsLoading(false);
            }
        };

        fetchChatRooms();
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredChats = chatRooms.filter((chat) =>
        chat.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="sidebar1">
            <div>
                <h1 className="chathead">Chats</h1>
            </div>

            <div className="sidebar-search-container">
                <input
                    type="text"
                    placeholder="Search chats..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="search-input"
                    disabled={isLoading}
                />
            </div>

            <div className="sidebar-chats">
                {isLoading && <p>Loading chat rooms...</p>}
                {error && <p>{error}</p>}

                {filteredChats.map((chat, index) => {
                    const lastMessage = messages[chat] && messages[chat].length > 0
                        ? messages[chat][messages[chat].length - 1]
                        : null;

                    return (
                        <div
                            key={index}
                            className="chat-item"
                            onClick={() => setSelectedChat(chat)}
                        >
                            <img src={monkeyImage} alt="contact" className="contact-image" />
                            <div className="chat-info">
                                <span className="chat-name">{chat}</span>
                                <p className="last-message">
                                    {lastMessage ? `${lastMessage.user}: ${lastMessage.message}` : 'No messages yet'}
                                </p>
                            </div>
                        </div>
                    );
                })}

                {filteredChats.length === 0 && !isLoading && (
                    <p>No chats found</p>
                )}
            </div>

            {/* Back button positioned at the bottom */}
            <div className="sidebar-footer">
                <button className="sidebar-back-button">
                    <Link to="/home">Back</Link>
                </button>
            </div>
        </div>
    );
};

export default Sidebar;

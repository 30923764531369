import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode'; // Fix the incorrect import
import axios from 'axios';

const BusBusiness = ({ registereddId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [grabedid, setGrabedid] = useState(null);
  const [formData, setFormData] = useState({
    businessType: '',
    interest: '',
    establishedDate: '',
    industry: '',
    location: '',
    employees: '',
    legalEntity: '',
    description: '',
    products: '',
    highlights: '',
    facility: '',
    ownership: '',
  });

  // Redirect non-user roles
  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role !== 'user') {
      navigate('/');
    }
  }, [navigate]);

  // Decode token and set user details
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      if (decoded.role !== 'user') {
        navigate('/');
      }
    }
  }, [navigate]);

  // Grab registeredId from query params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('registeredId') || registereddId;
    if (id) {
      setGrabedid(id);

    }
  }, [location, registereddId]);

  console.log("businedss////// tab",grabedid)

  // Fetch user details from the server
  useEffect(() => {
    if (grabedid) {
      axios
        .get(`https://api.pmitnetwork.com/api/getuserdetails/afterloginwithid/${grabedid}`)
        .then((response) => {
          console.log("Businesstab" ,response.data);
          const data = response.data[0];
          setFormData({
            businessType: data.businessType || '',
            interest: data.interest || '',
            establishedDate: data.establishedDate || '',
            industry: data.industry || '',
            location: data.location || '',
            employees: data.employees || '',
            legalEntity: data.legalEntity || '',
            description: data.description || '',
            products: data.products || '',
            highlights: data.highlights || '',
            facility: data.facility || '',
            ownership: data.ownership || '',
          });
        })
        .catch((error) => {
          console.error('Error fetching user details:', error);
        });
    }
  }, [grabedid]);

  console.log("formadatapage",formData);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handleContinue = () => {
    navigate(`/businessprofile?section=Plans&registeredId=${grabedid}`); // Navigate to the "Proofs" tab
  };

  // Handle form submission
  const handleSubmit = () => {
    axios
      .put(`https://api.pmitnetwork.com/api/updateuserdetails/businesstab/${grabedid}`, formData)
      .then(() => {
        alert('Business information updated successfully!');
      })
      .catch((error) => {
        console.error('Error updating business information:', error);
      });
  };

  return (
    <Box
      sx={{
        maxWidth: '800px',
        mx: 'auto',
        mt: 4,
        p: 4,
        borderRadius: 2,
        boxShadow: 2,
        backgroundColor: '#ffffff',
      }}
    >
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, color: '#333' }}>
        Business Information
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
        Information entered here is displayed publicly to match you with the right set of investors and buyers.
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>You are a(n)</InputLabel>
            <Select
              value={formData.businessType}
              onChange={(e) => handleInputChange(e, 'businessType')}

              name="businessType"
              size="medium"
              MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
            >
              <MenuItem value="Business Owner">Business Owner</MenuItem>
              <MenuItem value="Investor">Investor</MenuItem>
              <MenuItem value="Advisor">Advisor</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>You are interested in</InputLabel>
            <Select
              value={formData.interest}
              onChange={(e) => handleInputChange(e, 'interest')}

              name="interest"
              size="medium"
              MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
            >
              <MenuItem value="Investment">Investment</MenuItem>
              <MenuItem value="Selling the Business">Selling the Business</MenuItem>
              <MenuItem value="Partnership">Partnership</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Other fields */}
{/* 
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>You are interested in</InputLabel>
            <Select defaultValue="" size="medium" MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}>
              <MenuItem value="Investment">Investment</MenuItem>
              <MenuItem value="Selling the Business">Selling the Business</MenuItem>
              <MenuItem value="Partnership">Partnership</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item xs={12} sm={6}>
          <TextField
            label="Business Established Date"
            variant="outlined"
            fullWidth
            type="date"
            InputLabelProps={{ shrink: true }}
            size="medium"
            value={formData.establishedDate} 
            onChange={(e) => handleInputChange(e, 'establishedDate')}


            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>Industry</InputLabel>
            <Select defaultValue=""  value={formData.industry}              onChange={(e) => handleInputChange(e, 'industry')}

size="medium" MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}>
              <MenuItem value="Technology">Technology</MenuItem>
              <MenuItem value="Healthcare">Healthcare</MenuItem>
              <MenuItem value="Retail">Retail</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Location"
            variant="outlined"
            fullWidth
            size="medium"
            value={formData.location} 
            onChange={(e) => handleInputChange(e, 'location')}


            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Permanent Employees"
            variant="outlined"
            fullWidth
            type="number"
            size="medium"
            value={formData.employees}
            onChange={(e) => handleInputChange(e, 'employees')}
 

            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>Legal Entity Type</InputLabel>
            <Select defaultValue=""            
             value={formData.legalEntity} 
             onChange={handleChange}

     size="medium" MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}>
              <MenuItem value="Private Limited">Private Limited</MenuItem>
              <MenuItem value="LLP">LLP</MenuItem>
              <MenuItem value="Proprietorship">Proprietorship</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Brief Description"
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            placeholder="Briefly describe the business."
            size="medium"
            value={formData.description} 
            onChange={(e) => handleInputChange(e, 'description')}



            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Top-selling Products/Services"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            placeholder="Describe products/services."
            size="medium"
            value={formData.products} 
            onChange={(e) => handleInputChange(e, 'products')}



            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Business Highlights"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            placeholder="List key highlights."
            size="medium"
            value={formData.highlights} 
            onChange={(e) => handleInputChange(e, 'highlights')}


            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Facility Description"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            placeholder="Describe the facility."
            size="medium"
            value={formData.facility} 
            onChange={(e) => handleInputChange(e, 'facility')}


            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Current Funding & Ownership"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            placeholder="Details on ownership/funding."
            size="medium"
            value={formData.ownership} 
            onChange={(e) => handleInputChange(e, 'ownership')}


            required
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            fullWidth
            sx={{
              fontWeight: 'bold',
              mt: 2,
            }}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
                variant="contained"
                color="secondary"
                size="medium"
                fullWidth
                onClick={handleContinue}  // Navigate on click
                sx={{
                  fontWeight: 'bold',
                  padding: '10px 16px',
                  marginTop: 2,
                }}
              >
                Continue
              </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusBusiness;


import React, { useState } from 'react';
import './SideBar.css';
import { FaBars } from 'react-icons/fa';
import newicon from "../images/newicon.svg";
import Homeicon from "../images/Homeicon.svg";
import Chat from "../images/chat.svg";
import Bell from "../images/bell.svg";
import Profile from "../images/profile.svg";
import Settings from "../images/settings.svg";
import MediaIcon from "../images/like.svg"; // Import the Media icon
import { useNavigate, Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const SideBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false); // State to show confirmation modal
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    Cookies.remove("token");
    navigate("/login");
  };

  const handleConfirmLogout = () => {
    setShowConfirmLogout(true); // Show the confirmation modal
  };

  const handleCancelLogout = () => {
    setShowConfirmLogout(false); // Close the confirmation modal
  };

  return (
    <>
      {/* Burger button for mobile */}
      <button className="burger-btn" onClick={toggleSidebar}>
        <FaBars />
      </button>

      {/* Sidebar */}
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className='sidebar-logo-container'>
          <div className='img-sidebar'>
            <img src={newicon} alt="logo" className="logo" />
          </div>
          <div className='sidebar-heading'>
            <p className='header1'>Put me</p>
            <p className='header2'>in touch</p>
          </div>
        </div>

        <ul className="menu">
          <li>
            <Link to="/admindashboard">
              <img src={Homeicon} alt="Home" className="menu-icon" /> Dashboard
            </Link>
          </li>
          <li>
            <img src={Chat} alt="Discuss" className="menu-icon" /> Discuss
          </li>
          <li className={location.pathname === '/post-management' ? 'active' : ''}>
            <Link to="/post-management">
              <img src={Profile} alt="Post Management" className="menu-icon" /> Post Management
            </Link>
          </li>
          <li>
            <img src={Bell} alt="Notifications" className="menu-icon" /> Notifications
          </li>
          <li>
            <img src={Profile} alt="Profile" className="menu-icon" /> Profile
          </li>
          <li >
            <Link to="/media">
              <img src={MediaIcon} alt="Media" className="menu-icon" /> Media
            </Link>
          </li>
          <li>
            <Link to="/settings" style={{ textDecoration: 'none', color: 'inherit' }}>
              <img src={Settings} alt="Settings" className="menu-icon" /> Settings
            </Link>
          </li>
        </ul>

        {/* Sidebar Buttons - Hide these in desktop view */}
        <div className="sidebar-consumer-butons">
          <button className="sidebar-back-buttons"><Link to="/user">Back</Link></button>
          <button className="sidebar-logout-buttons" onClick={handleConfirmLogout}>Logout</button>
        </div>
      </div>

      {/* Confirmation Modal */}
      {showConfirmLogout && (
        <div className="confirm-logout-overlay">
          <div className="confirm-logout-box">
            <h3>Are you sure you want to logout?</h3>
            <div className="confirm-logout-buttons">
              <button className="confirm-btn" onClick={handleLogout}>Yes, Logout</button>
              <button className="cancel-btn" onClick={handleCancelLogout}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SideBar;

import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
 
const plans = [
  {
    title: "Basic",
    price: 1000,
    details:
      "Basic Business users will be able to post it on the business and consumers.",
  },
  {
    title: "Standard",
    price: 3000,
    details: `Standard Business users will be able to create virtual meetups, knowledge-sharing sessions, workshops, small business flea or mela, and an appreciation platform to track progress. These activities need to be approved and monitored by admin. They will also be able to post on the business and consumer section.`,
  },
  {
    title: "Enterprise",
    price: 5000,
    details: `Enterprise Business users will be able to gather meetings, attend world-recognized webinars, create virtual meetups, knowledge-sharing sessions, workshops, small business flea or mela, and an appreciation platform to track progress. These activities need to be approved and monitored by admin. They will also be able to post on the business and consumer section.`,
  },
];
 
const Busplans = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(
    new Array(plans.length).fill("")
  );
 
  const handleCardClick = (index) => {
    setSelectedCard(index);
 
    const updatedOptions = new Array(plans.length).fill("");
    updatedOptions[index] = selectedOptions[index];
    setSelectedOptions(updatedOptions);
  };
 
 
  const handleRadioChange = (index, value) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[index] = value;
    setSelectedOptions(updatedOptions);
  };
 
  const calculatePrice = (basePrice, duration) => {
    let totalPrice = basePrice;
 
    if (duration === "Quarterly") {
      totalPrice = basePrice * 3;
    } else if (duration === "Half Yearly") {
      totalPrice = basePrice * 5;
    } else if (duration === "Yearly") {
      totalPrice = basePrice * 11;
    }
 
    return `Rs. ${totalPrice}`;
  };
 
  const handleSubmit = () => {
    alert("Redirecting to payment...");
  };
 
  return (
    <Box sx={{ padding: 4, fontFamily: "Manrope, sans-serif" }}>
      <Typography
        variant="h5"
        sx={{ mb: 3, color: "#333", fontWeight: "bold", textAlign: "center" }}
      >
        Select a Plan
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              onClick={() => handleCardClick(index)}
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "400px",
                borderRadius: 2,
                boxShadow:
                  selectedCard === index
                    ? "0 4px 20px rgba(0, 123, 255, 0.5)"
                    : "0 2px 10px rgba(0, 0, 0, 0.1)",
                border:
                  selectedCard === index
                    ? "2px solid #1976d2"
                    : "2px solid transparent",
                transition: "transform 0.3s, box-shadow 0.3s, border 0.3s",
                cursor: "pointer",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              {/* Green Tick Icon */}
              {selectedOptions[index] && (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    color: "green",
                  }}
                  disableRipple
                >
                  <CheckCircleIcon fontSize="small" />
                </IconButton>
              )}
 
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ color: "#333", fontWeight: "bold" }}
                >
                  {plan.title}
                </Typography>
 
                {/* Show radio buttons only if the card is selected */}
                {selectedCard === index && (
                  <RadioGroup
                    value={selectedOptions[index]}
                    onChange={(e) =>
                      handleRadioChange(index, e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="Quarterly"
                      control={<Radio />}
                      label={`Quarterly `}
                    />
                    <FormControlLabel
                      value="Half Yearly"
                      control={<Radio />}
                      label={`Half Yearly `}
                    />
                    <FormControlLabel
                      value="Yearly"
                      control={<Radio />}
                      label={`Yearly `}
                    />
                  </RadioGroup>
                )}
 
                <Typography
                  variant="h5"
                  color="primary"
                  sx={{ fontWeight: "bold", mt: 2 }}
                >
                  {selectedOptions[index]
                    ? calculatePrice(plan.price, selectedOptions[index])
                    : "Select Your Plan"}
                </Typography>
 
                <Typography
                  variant="body2"
                  sx={{ color: "#555", whiteSpace: "pre-line", mt: 1 }}
                >
                  {plan.details}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
 
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{
            mt: 2,
            px: 5,
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          Go to Payment
        </Button>
      </Box>
    </Box>
  );
};
 
export default Busplans;
 
 
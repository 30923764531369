import './App.css';
import Mainpage from './components/Mainpage';
import {BrowserRouter,Route,Routes} from 'react-router-dom';

import Home from './components/Home';
import Signup from './components/Signup';
import {Superadmin } from '../src/superadminComponent/Superadmin';
import  {Consumer}  from '../src/consumerComponent/Consumer';
import  {Business}  from './businessComponent/Business';
import {Loginpage} from "../src/logincomponent/loginpage";
import Login from "./logincomponent/Login";
import Registerfile from './logincomponent/Registerfile';
import Register from './logincomponent/Register';
import { User } from './logincomponent/User';
import { Forgetpassword } from './logincomponent/Forgetpassword';
import Businesshome from './components/Businesshome';
import ConsumersPage from './components/Consumerbusiness';
import Explorepage from './logincomponent/Explorepage';
import { Explorelogin } from './logincomponent/Explorelogin';
import ExploreRegister from './logincomponent/ExploreRegister';
import ChatApp from '../src/components-chat/ChatApp';
import MainProfile from '../src/consumerComponent/componentsProfile/MainProfile';
import Profile from '../src/consumerComponent/componentsProfile/Profile';
// import Profile from '../src/consumerComponent/componentsProfile/Profile'
import Busmainprofile from './businessComponent/Busmainprofile';
import Busprofile from './businessComponent/Busprofile';
import { Dashboard, Settings, Subscriptions } from '@mui/icons-material';
import Subscription from './consumerComponent/Subscription'
import Notifications from './consumerComponent/Notifications'
import Live from './consumerComponent/Live'
import AdminDashboard from './superadminComponent/AdminDashboard'
import Setting from './superadminComponent/Setting';
// import Bussettings from './components/Bussettings';
import Businesssetting from './components/Businesssetting'
import Bussetting from './businessComponent/Bussetting'
import Media from './superadminComponent/Media'
import Businessmedia from './components/Businessmedia'
import Busmedia from './businessComponent/Busmedia'
// import AdminDashboard from './superadminComponent/AdminDashboard';
function App() {
  return (
    
    <div>
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Mainpage/>}/>      
          <Route path="/home" element={<Home/>}/>
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/login" element={<Loginpage/>}/>
          <Route path="/register" element={<Login/>}/>
          <Route path="/Superadmin" element={<Superadmin/>} />
          <Route path="/forgetpassword" element={<Forgetpassword/>} />
          <Route path="/user" element={<User/>} />
          <Route path="/businesshome" element={<Businesshome/>} />
          <Route path="/consumerbusiness" element={<ConsumersPage/>} />
          <Route path="/explore" element={<Explorepage/>}/>
          <Route path="/log" element={<Explorelogin/>}/>
          <Route path="/Res" element={<ExploreRegister/>}/>
          <Route path="/discuss" element={<ChatApp/>}></Route>
          
          <Route path="/mainProfile" element={<MainProfile />} />

          <Route path="/subscription" element={<Subscription/>}/>
          <Route path="/profile/:userId" element={<Profile/>} />
          <Route path="/profile" element={<Profile/>} />

          <Route path="/businessmainProfile" element={<Busmainprofile/>} />
          <Route path="/businessprofile" element={<Busprofile/>} />
          <Route path="/businessprofile/:userId" element={<Busprofile/>} />
          <Route path="/notifications" element={<Notifications/>}/>
          <Route path="/live" element={<Live/>}/>
          {/* <Route path="/admindashboard" element={<AdminDashboard/>}/> */}
          <Route path="/settings" element={<Setting/>}/>
          <Route path ="/busisettings" element={<Businesssetting/>}/>
          
          <Route path ="/businsettings" element={<Bussetting/>}/>

          <Route path ="/media" element ={<Media/>}/>
          <Route path ="/busmedia" element={<Businessmedia/>}/>
          <Route path ="/busimedia" element={<Busmedia/>}/>
          <Route path ="/admindashboard" element={<AdminDashboard/>}/>

        </Routes>
      </div>
    </BrowserRouter>
       
    </div>
  );
}

export default App;

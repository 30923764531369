import React from "react";
import {
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import Sidebar from '../components/Sidebar';

const CreateEvent = () => {
  return (
    <div style={styles.page}>
      {/* Sidebar */}
      <div style={styles.sidebarContainer}>
        <Sidebar />
      </div>

      {/* Main Content */}
      <div style={styles.contentContainer}>
        <Box style={styles.content}>
          {/* Header */}
          <Typography variant="h4" style={styles.title}>
            Create Event
          </Typography>

          {/* Host Details */}
          <Box style={styles.hostDetails}>
            <img
              src="https://via.placeholder.com/50"
              alt="Host"
              style={styles.profileImage}
            />
            <Box>
              <Typography variant="subtitle1" style={styles.hostName}>
                Tanuja Tanu
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Host - Your profile
              </Typography>
            </Box>
          </Box>

          {/* Event Form */}
          <Box style={styles.form}>
            {/* Event Name */}
            <TextField
              label="Event name"
              variant="outlined"
              fullWidth
              margin="normal"
            />

            {/* Date and Time */}
            <Box style={styles.dateTimeRow}>
              <TextField
                label="Start date"
                type="date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Start time"
                type="time"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
                style={{ marginLeft: "10px" }}
              />
              <TextField
                label="Time zone"
                defaultValue="GMT+5:30"
                variant="outlined"
                fullWidth
                margin="normal"
                style={{ marginLeft: "10px" }}
              />
            </Box>

            {/* Event Type */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Is it in person or virtual?</InputLabel>
              <Select defaultValue="">
                <MenuItem value="In Person">In Person</MenuItem>
                <MenuItem value="Virtual">Virtual</MenuItem>
              </Select>
            </FormControl>

            {/* Visibility */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Who can see it?</InputLabel>
              <Select defaultValue="Put me in touch Chennai">
                <MenuItem value="Put me in touch Chennai">
                  Put me in touch Chennai
                </MenuItem>
                <MenuItem value="Public">Public</MenuItem>
                <MenuItem value="Friends Only">Friends Only</MenuItem>
              </Select>
            </FormControl>

            {/* Privacy */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Group event privacy</InputLabel>
              <Select defaultValue="Members only">
                <MenuItem value="Members only">Members only</MenuItem>
                <MenuItem value="Public">Public</MenuItem>
              </Select>
            </FormControl>

            {/* Event Details */}
            <TextField
              label="What are the details?"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              margin="normal"
            />
          </Box>

          {/* Footer */}
          <Box style={styles.footer}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={styles.createButton}
            >
              Create event
            </Button>
          </Box>
        </Box>
      </div>
    </div>
  );
};

// Styles
const styles = {
  page: {
    display: "flex",
    backgroundColor: "#f5f5f5",
    minHeight: "100vh",
  },
  sidebarContainer: {
    width: "250px", // Adjust based on Sidebar width
    flexShrink: 0,
    backgroundColor: "#ffffff", // Match Sidebar style
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  contentContainer: {
    flex: 1,
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
  },
  content: {
    width: "100%",
    maxWidth: "800px",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
  },
  hostDetails: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  profileImage: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  hostName: {
    fontWeight: "bold",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  dateTimeRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  footer: {
    marginTop: "20px",
  },
  createButton: {
    textTransform: "none",
    fontWeight: "bold",
  },
};

export default CreateEvent;

import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CssBaseline,
  createTheme,
  ThemeProvider,
  IconButton,
} from "@mui/material";
import { Bar, Doughnut } from "react-chartjs-2";
import { MdSettingsBrightness } from "react-icons/md";
import "chart.js/auto";

// Dashboard Component
const Dashboard = () => {
  const [darkMode, setDarkMode] = useState(true);

  // Toggle dark/light mode
  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  // MUI Theme
  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: { main: "#6c5ce7" },
      secondary: { main: "#74b9ff" },
    },
    typography: {
      fontFamily: "Arial, sans-serif",
      h4: {
        fontWeight: "bold",
      },
      h6: {
        fontWeight: "bold",
      },
    },
  });

  // Chart Data for Bar and Doughnut charts
  const barData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Revenue",
        data: [12000, 15000, 14000, 20000, 22000, 25000],
        backgroundColor: "#6c5ce7",
        borderRadius: 8,
      },
    ],
  };

  const doughnutData = {
    labels: ["Direct", "Referral", "Organic"],
    datasets: [
      {
        data: [50, 30, 20],
        backgroundColor: ["#6c5ce7", "#74b9ff", "#fdcb6e"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ padding: 4 }}>
        {/* Header Section */}
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Admin Dashboard</Typography>
          <IconButton
            onClick={toggleTheme}
            sx={{
              color: darkMode ? "white" : "black",
              backgroundColor: darkMode ? "#333" : "#f5f5f5",
              borderRadius: "50%",
              padding: "8px",
            }}
          >
            <MdSettingsBrightness size={24} />
          </IconButton>
        </Grid>

        {/* Cards Section */}
        <Grid container spacing={3} mt={3}>
          {["Total Sales", "Total Orders", "Visitors", "Refunded"].map(
            (title, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
                  <CardContent>
                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="h4" sx={{ mt: 2 }}>
                      {index === 0 ? "$120,784.02" : "28,834"}
                    </Typography>
                    <Typography color="textSecondary" sx={{ mt: 1 }}>
                      View Report →
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )
          )}
        </Grid>

        {/* Charts Section */}
        <Grid container spacing={3} mt={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6">Revenue</Typography>
                <Box sx={{ height: 300 }}>
                  <Bar data={barData} options={{ responsive: true }} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6">Traffic Channel</Typography>
                <Box sx={{ height: 300 }}>
                  <Doughnut data={doughnutData} options={{ responsive: true }} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Recent Activity Section */}
        <Grid container spacing={3} mt={3}>
          <Grid item xs={12}>
            <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6">Recent Activity</Typography>
                {/* Recent Activity Table */}
                <Box mt={2}>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography variant="body2">Customer</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2">Status</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2">Customer ID</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2">Retained</Typography>
                    </Grid>
                  </Grid>
                  {[1, 2, 3].map((item, index) => (
                    <Grid container key={index} mt={1}>
                      <Grid item xs={3}>
                        <Typography>John Doe</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>Active</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>#12345</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>$12,000</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;

import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button, Avatar, Divider, Tooltip, Card, CardContent, CardMedia, Chip } from '@mui/material';
import { LinkedIn, Facebook, Google, Star, RssFeed } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { Link } from 'react-router-dom';
import img1 from '../../images/linkdin.svg';
import img2 from '../../images/Facebook.svg';
import img3 from '../../images/google.svg';
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import dayjs from "dayjs";


const activities = [
  {
    title: 'Quartzite mining investment opportunity in Andhra Pradesh',
    location: 'Visakhapatnam',
    industry: 'Nonferrous Metal Mining',
    description: 'Located in the prime mining region of Vizianagaram.',
    rating: '8.1/10',
    partialStake: 'INR 15 crore for 30%',
    revenue: 'INR 6 crore @ 15% EBITDA',
    imageUrl: 'https://via.placeholder.com/150',
    viewers: ['Director', 'Pharmaceutical', 'Bangalore', 'Individual Investor / Buyer'],
    premium: true,
  },
  {
    title: 'IT Consultancy business in Mumbai seeking partners',
    location: 'Mumbai',
    industry: 'IT Services',
    description: 'Offers specialized IT solutions with a strong clientele and experienced workforce.',
    rating: '7.8/10',
    partialStake: 'INR 10 crore for 20%',
    revenue: 'INR 3 crore @ 18% EBITDA',
    imageUrl: 'https://via.placeholder.com/150',
    viewers: ['Manager', 'Healthcare', 'Delhi', 'Corporate Investor'],
    premium: false,
  },
  {
    title: 'Premium clothing brand available for acquisition',
    location: 'Bangalore',
    industry: 'Apparel Manufacturing',
    description: 'Renowned fashion brand with a loyal customer base and established online presence.',
    rating: '9.0/10',
    partialStake: 'INR 25 crore for 50%',
    revenue: 'INR 12 crore @ 22% EBITDA',
    imageUrl: 'https://via.placeholder.com/150',
    viewers: ['Investor', 'Retail', 'Mumbai', 'Individual Buyer'],
    premium: true,
  },
];

const ProfilePage = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});

  const[username, setUsername] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "user") {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "user") {
        navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    // Function to fetch user details after login
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `https://api.pmitnetwork.com/api/getuserdetails/afterlogin/${username}`
        );
        // console.log("data in Main Profile",response.data[0])
        // const registeredId = response.data[0]?.registeredId;

        setUserDetails(response.data[0]);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    if (username) {
      fetchUserDetails();
    }
  }, [username]);
  console.log("userdata in MainProfile", userDetails);

  const formatDate = (dateString) => {
    return dayjs(dateString, "YYYY-MM-DD HH:mm:ss").format("MMM. D, YYYY");
  };


  const handleUpdateClick = (userId) => {
    navigate(`/profile/:${userId}`);
  };

  return (
    // <div className="home-container">
    <div>
      {/* <div>
        <Sidebar/>
      </div> */}
    <div>
    {userDetails &&
    <Box
      sx={{
        maxWidth: '100%',
        mx: 'auto',
        p: 3,
        fontFamily: 'Manrope, sans-serif',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
      }}
    >
      <Grid container spacing={3}>
        {/* Left Sidebar */}
        <Grid item xs={12} md={4} lg={3}>
          <Box
            sx={{
              backgroundColor: '#f4f4f4',
              p: 3,
              borderRadius: 2,
              textAlign: 'center',
              boxShadow: 2,
              height: '100%',
            }}
          >
            <Avatar sx={{ width: 80, height: 80, mx: 'auto', bgcolor: 'purple', fontSize: 40 }}>{userDetails?.username?.charAt(0) || ""}</Avatar>
            <Typography variant="h6" sx={{ mt: 2 }}>
            {userDetails.username}
            </Typography>
            
            <Typography color="textSecondary">{userDetails.email}</Typography>
            <Typography color="textSecondary">Vizianagaram</Typography>
            <Typography color="textSecondary">Joined {formatDate(userDetails.DateofRegistered)}</Typography>
            <Typography color="Secondary" fontWeight={'bold'}>New User</Typography>

            <Button
              variant="text"
              sx={{ mt: 1, color: 'blue' }}
              onClick={()=>handleUpdateClick(userDetails.registeredId)}
            >
              Update details
            </Button>

            {/* Social Icons */}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 1 }}>
      <Tooltip title="LinkedIn">
        <img src={img1} alt="LinkedIn" style={{ width: 24, height: 24 }} />
      </Tooltip>
      <Tooltip title="Facebook">
        <img src={img2} alt="Facebook" style={{ width: 24, height: 24, }} />
      </Tooltip>
      <Tooltip title="Google">
        <img src={img3} alt="Google" style={{ width: 24, height: 24 }} />
      </Tooltip>
    </Box>
 

            <Divider sx={{ my: 2 }} />

            {/* Preferences */}
            <Box>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Feed Preferences
              </Typography>
              <Typography variant="body2">Industries: All industries</Typography>
              <Typography variant="body2">Locations: Vizianagaram, India</Typography>
              <Typography variant="body2">Deal Size: All sizes</Typography>
            </Box>

            <Divider sx={{ my: 2 }} />

            {/* Email Preferences */}
            <Box>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Email Preferences
              </Typography>
              <Typography variant="body2">Important Communication: Subscribed</Typography>
              <Typography variant="body2">Business Proposals: Real Time</Typography>
              <Typography variant="body2">New Opportunity Notifications: Fortnightly</Typography>
            </Box>

            <Button
              variant="contained"
              sx={{ mt: 3, width: '100%', backgroundColor: '#aaa', color: '#fff' }}
              disabled
            >
              No Active Profiles
            </Button>
            <Button
      component={Link}
      to="/home"
      variant="contained"
      sx={{
        mt: 3,
        width: '100%',
        backgroundColor: '#4a90e2', // A nice blue color
        color: '#fff',
        textDecoration: 'none',
        '&:hover': {
          backgroundColor: '#357ABD', // Darker blue on hover
        },
      }}
    >
      Back
    </Button>
          </Box>
        </Grid>

        {/* Right Content */}
        <Grid item xs={12} md={8} lg={9}>
          <Box sx={{ backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 2 }}>
            <Typography variant="h5">Hi {userDetails.username},</Typography>
            <Button
  variant="text"
  sx={{
    position: 'absolute',
    top: 34,
    right: 34,
    fontSize: '1rem',
    bgcolor: 'primary.main',
    color: 'primary.contrastText',
    padding: '6px 12px',
    borderRadius: '8px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      bgcolor: 'primary.dark',
      boxShadow: '0px 4px 12px rgba(0, 0, 255, 0.5)', // Glow effect
      transform: 'scale(1.05)', // Slightly enlarges the button on hover
    },
  }}
  onClick={()=>handleUpdateClick(userDetails.registeredId)}
>
  Profile Details
</Button>


            <Typography sx={{ mt: 1, color: '#666' }}>
              Welcome to PMIT. We help you acquire businesses, raise capital for your company, find franchise
              partners, or realize value through a strategic sale. Our global network of investors and businesses
              helps you successfully achieve your strategic objectives.
            </Typography>

            <Divider sx={{ my: 3 }} />

            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
              <RssFeed sx={{ color: '#ff9800', mr: 1 }} />
              <Typography variant="h6">Latest Activity</Typography>
            </Box>

            {activities.map((activity, index) => (
              <Card key={index} sx={{ display: 'flex', mb: 2, boxShadow: 1, position: 'relative' }}>
                {activity.premium && (
                  <Chip
                    label="PREMIUM"
                    sx={{ position: 'absolute', top: 8, right: 8, color: 'white', backgroundColor: '#4caf50' }}
                  />
                )}
                <CardMedia component="img" sx={{ width: 120 }} image={activity.imageUrl} alt="Activity Image" />
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography variant="body2" color="textSecondary">
                    New activity in <strong>{activity.location}</strong> and <strong>{activity.industry}</strong>
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1, fontWeight: 'bold' }}>
                    {activity.title}
                  </Typography>
                  <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                    <Star sx={{ color: '#ff9800', mr: 0.5 }} />
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{activity.rating}</Typography>
                  </Box>
                  <Typography variant="body2" sx={{ mt: 1, color: '#666' }}>
                    - {activity.description}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    <strong>Partial Stake Sale:</strong> {activity.partialStake}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Run Rate Revenue:</strong> {activity.revenue}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Viewed by {activity.viewers.join(', ')}
                  </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                  <Button variant="contained" sx={{ backgroundColor: '#333', color: '#fff', textTransform: 'none' }}>
                    Contact Business
                  </Button>
                </Box>
              </Card>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  }
    </div>
    </div>
  );
};

export default ProfilePage;

// import React from "react";
// import "./Homepart.css";
// import { Link } from "react-router-dom";
// import { Autoplay } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/autoplay";
// import img2 from "../images/user1.jpg";
// import img3 from "../images/user2.jpg";
// import img4 from "../images/user4.jpg";
// import { BsBoxArrowUp } from "react-icons/bs";
// import { TbMessageChatbotFilled } from "react-icons/tb";
// import { BsChatHeartFill } from "react-icons/bs";
// import impress1 from "../images/Impress.svg";
// import impress2 from "../images/Impress-one.svg";

// import impress3 from "../images/Impress-two.svg";




// const slidesData = [
//   {
//     image: img4,
//     description: "Where it is located?",
//   },
//   {
//     image: img2,
//     description: "What kind of Antique you are selling?",
//   },
//   {
//     image: img3,
//     description: "Amazing !",
//   },
//   {
//     image: img4,
//     title: "Slide 2 Title",
//     description: "Hey I'm Interested !",
//   }
  
// ];

// const Homepart = () => {
//   return (
//     <div className="Homepart-full-container">
//       <div className="home-part-text">
//       <div className="homepart-text-one">
//         <p>The smater way to</p>
//         <p className="homepart-text-one-two">connect,collaborate,</p>
//         <p>and grow!</p>
//       </div>
//       <div className="homepart-text-two">
//         <p>Tailored partnership | Limitless opportunities.</p>
//       </div>
//       <div className="homepart-text-three-btn-wholediv">
//       <Link to="/explo">
//             <button className="homepart-text-three-btn">Explore Now</button>
//           </Link>
//       </div>
      
//       </div>
//       <div className="home-part-coursel">
//         <div className="home-part-coursel-content">
//           <div className="bg-light-subtle homepart-text-coursel-content">
//             <p class='h-p-t-c-c-para'>#Antique shop for sale !</p>
//           </div>
//           <div className="swiper-content-container-home">
//             <Swiper
//               direction={"vertical"}
//               slidesPerView={2}
//               spaceBetween={30}
//               autoplay={{
//                 delay: 3000, // Delay between transitions (in ms)
//                 disableOnInteraction: false, // Continue autoplay even when user interacts
//               }}
//               modules={[Autoplay]} // Only use Autoplay module
//               className="mySwiper main-swiper-div-css"
             
//             >
//               {slidesData.map((slide, index) => (
//                 <SwiperSlide key={index}>
//                   <div className="homepart-main-swiper-whole-content">
//                     <div className="homepart-main-swiper-image-content-class">
//                       <img
//                         src={slide.image}
//                         alt={`Slide ${index + 1}`}
//                         className="homepart-main-swiper-image-dimensions"
//                       />
//                     </div>
//                     <div className="bg-light-subtle homepart-main-swiper-image-content-related">

//                       <p className="homepart-main-swiper-image-content-related-para">{slide.description}</p>
//                     </div>
//                   </div>
//                 </SwiperSlide>
//               ))}
//             </Swiper>
//           </div>
//         </div>
//         <div className="home-part-coursel-icons-data">
        
//         {/* <BsChatHeartFill />
//         <TbMessageChatbotFilled />
//         <BsBoxArrowUp /> */}

//         <img src={impress1} className="hpcid-dim"></img>
//         <img src={impress2}></img>
//         <img src={impress3}></img>

      


//         </div>
//       </div>
//     </div>
//   );
// };

// export default Homepart;





import React from "react";
import "./Homepart.css";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import img2 from "../images/user1.jpg";
import img3 from "../images/user2.jpg";
import img4 from "../images/user4.jpg";
import { BsBoxArrowUp } from "react-icons/bs";
import { TbMessageChatbotFilled } from "react-icons/tb";
import { BsChatHeartFill } from "react-icons/bs";
import impress1 from "../images/Impress.svg";
import impress2 from "../images/Impress-one.svg";
 
import impress3 from "../images/Impress-two.svg";
 
 
 
 
const slidesData = [
  {
    image: img4,
    description: "Where it is located?",
  },
  {
    image: img2,
    description: "What kind of Antique you are selling?",
  },
  {
    image: img3,
    description: "Amazing !",
  },
  {
    image: img4,
    title: "Slide 2 Title",
    description: "Hey I'm Interested !",
  }
 
];
 
const Homepart = ({onExploreClick}) => {
  return (
    <div className="Homepart-full-container">
      <div className="home-part-text">
      <div className="homepart-text-one">
        <p>The smater way to</p>
        <p className="homepart-text-one-two">connect,collaborate,</p>
        <p>and grow!</p>
      </div>
      <div className="homepart-text-two">
        <p>Tailored partnership | Limitless opportunities.</p>
      </div>
      <div className="homepart-text-three-btn-wholediv">
          <button onClick={onExploreClick} className="homepart-text-three-btn">
            Explore Now
          </button>
        </div>
     
      </div>
      <div className="home-part-coursel">
        <div className="home-part-coursel-content">
          <div className="bg-light-subtle homepart-text-coursel-content">
            <p class='h-p-t-c-c-para'>#Antique shop for sale !</p>
          </div>
          <div className="swiper-content-container-home">
            <Swiper
              direction={"vertical"}
              slidesPerView={2}
              spaceBetween={30}
              autoplay={{
                delay: 3000, // Delay between transitions (in ms)
                disableOnInteraction: false, // Continue autoplay even when user interacts
              }}
              modules={[Autoplay]} // Only use Autoplay module
              className="mySwiper main-swiper-div-css"
             
            >
              {slidesData.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="homepart-main-swiper-whole-content">
                    <div className="homepart-main-swiper-image-content-class">
                      <img
                        src={slide.image}
                        alt={`Slide ${index + 1}`}
                        className="homepart-main-swiper-image-dimensions"
                      />
                    </div>
                    <div className="bg-light-subtle homepart-main-swiper-image-content-related">
 
                      <p className="homepart-main-swiper-image-content-related-para">{slide.description}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="home-part-coursel-icons-data">
       
        {/* <BsChatHeartFill />
        <TbMessageChatbotFilled />
        <BsBoxArrowUp /> */}
 
        <img src={impress1} className="hpcid-dim"></img>
        <img src={impress2}></img>
        <img src={impress3}></img>
 
     
 
 
        </div>
      </div>
    </div>
  );
};
 
export default Homepart;
 
 

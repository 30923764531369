import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Forgetpassword.css';
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
 
 
export const Forgetpassword = () => {
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [buttonText, setButtonText] = useState('Send Mail');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSendingMail, setIsSendingMail] = useState(false);
  const navigate = useNavigate();
 
  const handleSendMail = async () => {
    try {
      setIsSendingMail(true);
      const response = await fetch('https://api.pmitnetwork.com/sendOTP', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
 
      const data = await response.json();
      if (response.ok) {
        if (data.success) {
          setShowOTPInput(true);
          setButtonText('Submit OTP');
        } else {
          alert('Error sending OTP. Please try again.');
        }
      } else if (response.status === 400) {
        alert('Email Address Does not exist in Database');
      } else {
        alert('An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsSendingMail(false);
    }
  };
 
 
  const handleVerifyOTP = async () => {
    try {
      const response = await fetch('https://api.pmitnetwork.com/verifyOTP', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });
 
      const data = await response.json();
      if (data.success) {
        setShowPasswordFields(true);
        setShowOTPInput(false);
        setButtonText('Reset Password');
      } else {
        alert('OTP not matched. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('Error verifying OTP. Please try again.');
    }
  };
 
  const handleResetPassword = async () => {
    // Check if the new password and confirm password match
    if (newPassword !== confirmPassword) {
      alert('New Password and Confirm Password do not match.');
      return;
    }
 
    try {
      const response = await fetch('https://api.pmitnetwork.com/updatePassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, newPassword }),
      });
 
      const data = await response.json();
      if (data.success) {
        alert('Password updated successfully!');
        // You may want to redirect the user to a login page or perform other actions after updating the password
        navigate('/login');
      } else {
        alert('Error updating password. Please try again.');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      alert('Error updating password. Please try again.');
    }
  };
 
  return (
    <div className='fp_forgetboxouter d-flex flex-column justify-content-center align-items-center'>
      <Link  className='fp_back' to='/login'><p className='zoom-effect-p'><FaRegArrowAltCircleLeft/>Login</p></Link>
      <div className='fp_forgetpass'>
        {!showPasswordFields && (
          <>
            <input
              placeholder='Enter your Email id'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={showOTPInput}
              className='lo-inp'
            />
            {showOTPInput && (
              <input
                placeholder='Enter OTP'
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                disabled={showPasswordFields}
                className='lo-inp'
 
              />
            )}
          </>
        )}
        {showPasswordFields && (
          <>
            <input
              type='password'
              placeholder='New Password'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className='lo-inp'
 
            />
            <input
              type='password'
              placeholder='Confirm Password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className='lo-inp'
 
            />
          </>
        )}
        <button className='loinp-but'
          onClick={showPasswordFields ? handleResetPassword : (showOTPInput ? handleVerifyOTP : handleSendMail)}
          disabled={isSendingMail}
        >
          {isSendingMail ? 'Sending Mail...' : buttonText}
        </button>
      </div>
    </div>
  );
};
 